import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from "react-image-upload";
import ProfileSidebar from "./common/ProfileSidebar";
import Swal from "sweetalert2";
import axiosClient from "../Services/Axios/axios";
import { ClipLoader } from "react-spinners";

const Careers = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState();
  const [buttonloader, setButtonloader] = useState(false);
  const fileInputRef = useRef();

  const [reviewData, setReviewData] = useState({
    event_id: "",
    user_id: "",
    review: "",
    image: "",
  });
  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      const responce = await axiosClient.post("/users/uploadimg", formData);
      if (!responce.data.error) {
        setReviewData({ ...reviewData, image: responce.data.file });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Image is too large",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonloader(true);
    try {
      const data = await axiosClient.post(`/events/addReview`, reviewData);
      if (!data.data.error) {
        Swal.fire({
          icon: "success",
          title: "Thanks for your Review",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setReviewData({
        ...reviewData,
        review: "",
        image: "", // Resetting the image field
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clearing the file input
      }
      setButtonloader(false);
    } catch (error) {
      console.log(error);
      setButtonloader(false);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    setUserData(user);
    setReviewData({
      ...reviewData,
      event_id: id,
      user_id: user.id,
    });
  }, []);
  console.log(reviewData);
  return (
    <>
      <div className="all_suport_bg">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>
                <img src="/images/brd_rowwo.png" alt="" /> Events
                <img src="/images/brd_rowwo.png" alt="" /> Add Review
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>
              <div className="col-lg-9">
                <div className="form_boxx profff_eventss profilss">
                  <h4 className="br_space">Add Review</h4>
                  <div className="form_arar mt-0">
                    <div className="mang_und_listst mt-0">
                      <form action="" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12">
                                <div class="form-group">
                                  <label>Enter Review</label>
                                  <textarea
                                    class="form-control"
                                    name="review"
                                    onChange={(e) =>
                                      setReviewData({
                                        ...reviewData,
                                        review: e.target.value,
                                      })
                                    }
                                    value={reviewData.review}
                                    rows="7"
                                    cols="7"
                                  ></textarea>
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div class="form-group" id="browsers">
                                  <label>Attach Image</label>
                                  <input
                                    type="file"
                                    className="form-control bor1"
                                    onChange={handleImageChange}
                                    ref={fileInputRef} // Attach the ref here
                                    placeholder="Attach Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-4">
                            <div class="form-group">
                              <button
                                type="submit"
                                value="Update Profile"
                                class="sub_mitess"
                                disabled={buttonloader}
                              >
                                {buttonloader ? (
                                  <ClipLoader color="#fff" size={"25"} />
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;
