import React, { useEffect, useState } from "react";
import axiosClient from "../../Services/Axios/axios";
import { Link } from "react-router-dom";
import CommanPagination from "../common/CommanPagination/CommanPagination";
import dayjs from "dayjs";
import BeatLoader from "react-spinners/BeatLoader"; // Ensure you have installed 'react-spinners'
import Swal from "sweetalert2";

const EnrolledEvents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [enrolledEventData, setEnrolledEventData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch Enrolled Events
  const getAllEnrolledEvent = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(
        `/events/getEnrolledEvents?language=en&pageSize=${limit}&pageNo=${currentPage}`
      );
      setLoading(false);
      setEnrolledEventData(response.data?.error ? [] : response.data);
    } catch (error) {
      console.error("Error fetching enrolled events:", error);
      setLoading(false);
      setEnrolledEventData([]);
    }
  };

  const handleCheckIn = async (id) => {
    try {
      const response = await axiosClient.post(`/events/checkin`, {
        event_id: id,
      });
      if (response.data && !response.data.error) {
        getAllEnrolledEvent();
        Swal.fire({ icon: "success", title: "Event Checked In Successfully" });
      }
    } catch (error) {
      console.error("Check-in failed:", error);
    }
  };

  const handleWithdraw = async (id) => {
    try {
      const response = await axiosClient.post(`/events/withdraw`, {
        event_id: id,
      });
      if (response.data && !response.data.error) {
        getAllEnrolledEvent();
        Swal.fire({ icon: "success", title: "Event Withdrawn Successfully" });
      }
    } catch (error) {
      console.error("Withdraw failed:", error);
    }
  };

  const handleCheckOut = async (id) => {
    try {
      const response = await axiosClient.post(`/events/checkout`, {
        event_id: id,
      });
      if (response.data && !response.data.error) {
        getAllEnrolledEvent();
        Swal.fire({ icon: "success", title: "Event Checked Out Successfully" });
      }
    } catch (error) {
      console.error("Check-in failed:", error);
    }
  };

  useEffect(() => {
    getAllEnrolledEvent();
  }, [currentPage, limit]);

  return (
    <div className="main_tabs_data">
      {loading ? (
        <div className="member-loader">
          <BeatLoader color="#097C81" />
        </div>
      ) : (
        <>
          {enrolledEventData?.data?.rows.map((item, i) => {
            return (
              <div className="un_data_list" key={i}>
                <Link to={`/events-details/${item.event?.page_url}`}>
                  <div className="usr_mgss">
                    <img
                      src={item.event?.image}
                      alt={item.event?.title || "Event"}
                    />
                  </div>
                </Link>
                <div className="cntent_tx">
                  <div className="dts_shrerss">
                    <div className="dat_time">
                      <span className="datess">
                        <img src="/images/g_uil_calender.png" alt="Calendar" />
                        {dayjs(item.event?.event_date).format("DD, MMM YYYY")}
                      </span>
                      <span className="spacess">&nbsp;</span>
                      <span className="datess">
                        <img src="/images/tabler_clock.png" alt="Clock" />
                        {item.event?.event_time} - {item.event?.event_time_end}
                      </span>
                    </div>
                    <div className="sh_linkss">
                     <div className="ul_links">
                      <ul>
                        <li>
                          <Link to="#">
                            <img src="/images/g-calendar.png" alt="Calendar" />
                            &nbsp; Add to calendar
                          </Link>
                        </li>
                      </ul>
                      </div>
                    </div>
                  </div>
                  <h2>
                    {item.event?.title}{" "}
                    <span className="peplss">
                      {item.event?.registered_seat > 0
                        ? `${
                            item.event?.registered_seat || 0
                          } people have registered`
                        : ""}
                    </span>
                  </h2>
                  <p>{item.event?.description}</p>
                  <div className="adress">
                    <div className="adrss">
                      <img
                        src="/images/g_ic_sharp-location-on.png"
                        alt="Location"
                      />
                      {item.event?.event_location}
                    </div>
                    <div className="red_mr">
                      {item.booking_status === 0 && (
                        <>
                          <Link
                            onClick={() => handleWithdraw(item.event?.id)}
                            className="red_mr fl_rights brd_5"
                          >
                            Withdraw
                          </Link>
                          <Link
                            onClick={() => handleCheckIn(item.event?.id)}
                            className="red_mr brd_5"
                          >
                            Check In
                          </Link>
                        </>
                      )}

                      {item.booking_status === 1 && (
                        <>
                          <span className="red_checkin">
                            Check In :{" "}
                            {dayjs(item.event?.checkin_date).format(
                              " HH : mm A"
                            )}
                          </span>
                          <Link
                            onClick={() => handleCheckOut(item.event?.id)}
                            className="red_mr brd_5"
                          >
                            Check Out
                          </Link>
                        </>
                      )}

                      {item.booking_status === 2 && (
                        <>
                          <Link
                            to={`/add-review/${item.event?.id}`}
                            className="red_mr brd_5"
                          >
                            Add Review
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <CommanPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
            totalCounts={enrolledEventData?.data?.totalElement || 0}
            totalPage={enrolledEventData?.data?.totalPage || 0}
          />
        </>
      )}
    </div>
  );
};

export default EnrolledEvents;
