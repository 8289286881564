import { Link, useLocation, useNavigate } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import axiosClient from "../../Services/Axios/axios";
import { Button, Modal } from "react-bootstrap";
import ScannerModal from "../Events/ScannerModal";

const Header = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [borderShow, setBorderShow] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({});

  const [show, setShow] = useState(false);
  const [showscan, setShowscan] = useState(false);
  const [userNewData, setUserNewData] = useState({});
  // const userNewData = JSON.parse(localStorage.getItem("userData"));
  const cameracl = () => setShowscan(true);
  const handleClose1 = () => setShowscan(false);
  const videoshow = () => {
    setShow(true);
    localStorage.removeItem("userData");
  };

  const userData = localStorage.getItem("userData");

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 82
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setBorderShow(false);
    } else {
      setBorderShow(true);
    }
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to logout?",
      showCancelButton: true,
      confirmButtonText: "Log out",
    }).then((result) => {
      if (result.isConfirmed) {
        navigation("/");
        localStorage.clear();
        Swal.fire({
          title: "Log out",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    });
  };

  const handleProfile = async () => {
    const response = await axiosClient.get("/users/getByID");
    if (!response.data.error) {
      localStorage.setItem("userData", JSON.stringify(response.data.data));
      setUserNewData(response.data.data);
      navigation("/impact");
    }
  };
  useEffect(() => {
    if (userData) {
      const data = JSON.parse(userData);
      setUserDetail(data);
    }
  }, [userData]);

  return (
    <>
      <header
        className={`header-section ${borderShow === true ? "bor_botmm" : ""}`}
        id="fixedds"
      >
        <div className="container">
          <nav>
            <div className="line justify-content-between">
              <div className="lgo_mg">
                <Link to="/">
                  <img src="/images/w_logo.png" alt="" className="lgo_parts" />
                </Link>
              </div>

              <div className={`top-nav s-12 l-12 ${isMenuOpen ? "open" : ""}`}>
                {userData !== null && (
                  <>
                    {" "}
                    <div className="user_prfilss">
                      <div className="us_mgsss">
                        <img
                          src={`${
                            userNewData?.profile_image
                              ? userNewData?.profile_image
                              : "/images/my_profl.png"
                          }`}
                          alt=""
                        />
                      </div>
                      <h4>{userNewData?.first_name}</h4>
                      <p>{userNewData?.email}</p>
                    </div>
                    <ul className="usr_linkss_lgo">
                      <li>
                        <Link to="/impact">
                          <span className="spss_are">
                            <img src="/images/mb_v1.png" alt="" />
                          </span>{" "}
                          Impact
                        </Link>
                      </li>

                      <li>
                        <Link to="/Profile">
                          <span className="spss_are">
                            <img src="/images/mb_v2.png" alt="" />
                          </span>{" "}
                          Profile
                        </Link>
                      </li>

                      <li>
                        <Link to="/kyc">
                          <span className="spss_are">
                            <img src="/images/mb_v3.png" alt="" />
                          </span>{" "}
                          KYC
                        </Link>
                      </li>

                      <li>
                        <Link to="/tax-information">
                          <span className="spss_are">
                            <img src="/images/mb_v4.png" alt="" />
                          </span>{" "}
                          Tax Information
                        </Link>
                      </li>

                      <li>
                        <Link to="/gratitude-system">
                          <span className="spss_are">
                            <img src="/images/mb_v5.png" alt="" />
                          </span>{" "}
                          Gratitude
                        </Link>
                      </li>

                      <li>
                        <Link to="/">
                          <span className="spss_are">
                            <img src="/images/mb_v7.png" alt="" />
                          </span>{" "}
                          Events
                        </Link>
                      </li>

                      {userNewData?.role_type !== 5 && (
                        <>
                          {" "}
                          <li>
                            <Link to="/campaign">
                              <span className="spss_are">
                                <img src="/images/mb_v6.png" alt="" />
                              </span>{" "}
                              Campaigns
                            </Link>
                          </li>
                          <li>
                            <Link to="/transaction-history">
                              <span className="spss_are">
                                <img src="/images/mb_v8.png" alt="" />
                              </span>{" "}
                              Transactions
                            </Link>
                          </li>
                        </>
                      )}

                      <li>
                        <Link to="/referral">
                          <span className="spss_are">
                            <img src="/images/mb_v9.png" alt="" />
                          </span>{" "}
                          Referral
                        </Link>
                      </li>
                    </ul>
                  </>
                )}

                <ul className="front_web">
                  <li>
                    <Link to="/" onClick={toggleMenu}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" onClick={toggleMenu}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/donate" onClick={toggleMenu}>
                      Donate{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/events" onClick={toggleMenu}>
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={toggleMenu}>
                      Contact Us
                    </Link>
                  </li>
                  {userData === null ? (
                    <>
                      <li className="mb_view_show">
                        <Link to="/auth-screen" onClick={toggleMenu}>
                          Login
                        </Link>
                      </li>
                      <li className="mb_view_show">
                        <Link to="/auth-screen" onClick={toggleMenu}>
                          Sign up
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link to="/" onClick={handleLogout}>
                          Logout
                        </Link>
                      </li>
                    </>
                  )}
                </ul>

                <div id="point_scan_arae">
                  <div className="merit_point">
                    <div className="pnt_logos">
                      <img src="/images/point_logo.png" alt="" />
                    </div>
                    <div className="cnt_textxt">
                      <h2>400</h2>
                      <p>Merit Points</p>
                    </div>
                  </div>

                  <div className="scanss">
                    <div className="scansses">
                      <h5 onClick={cameracl}>
                        Scan Now{" "}
                        <span className="scaan">
                          <img src="/images/scanner.png" alt="" />
                        </span>
                      </h5>
                    </div>
                    <p>Note : scan now for your events/donations</p>
                  </div>
                  <ScannerModal open={showscan} onClose={handleClose1} />
                </div>

                <div className="bk_now_flds mb_view_hide">
                  {/* <div id="loging_userss">
                    <div className="notif_tion">
                      <span className="countss">2</span>
                      <img
                        src="//images/notification.png"
                        alt=""
                        className="lgo_parts"
                      />
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <div className="usr_sm_mgss">
                          <img
                            src="//images/userr_sm_img.png"
                            alt=""
                            className="lgo_parts"
                          />
                        </div>
                        Jackson
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="">Profile</Dropdown.Item>
                        <Dropdown.Item href="">Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}

                  {userData === null ? (
                    <ul>
                      <li>
                        <Link to="/auth-screen">Login</Link>{" "}
                        <span className="spss_lst">/</span>{" "}
                        <Link to="/auth-screen">Sign up</Link>
                      </li>
                    </ul>
                  ) : (
                    userDetail && (
                      <div id="loging_userss">
                        <div className="notif_tion">
                          <span className="countss">2</span>
                          <img
                            src="/images/notification.png"
                            alt=""
                            className="lgo_parts"
                          />
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <div className="usr_sm_mgss">
                              <img
                                src={
                                  userDetail.profile_image
                                    ? userDetail.profile_image
                                    : "/images/my_profl.png"
                                }
                                alt=""
                                className="lgo_parts"
                              />
                            </div>
                            {userDetail.first_name}
                            {/* smit */}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={handleProfile}>
                              Profile
                            </Dropdown.Item>
                            {/* <Link to="/profile">Profile</Link> */}
                            <Dropdown.Item onClick={handleLogout} href="">
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )
                  )}
                </div>
              </div>
              <button className="hamburger" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
