import React, { useState } from "react";
import LogoIcon from "../../assets/Images/MiniLogo.png";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

const Register = () => {
  const navigate = useNavigate();
  const [registerInfo, setRegisterInfo] = useState({
    first_name: "",
    email: "",
    password: "",
  });

  const firebaseConfig = {
    apiKey: "AIzaSyAIkf3xnqZPqhWW-oFj7zmzavRlPdx7F5Q",
    authDomain: "world-trust-63da5.firebaseapp.com",
    projectId: "world-trust-63da5",
    storageBucket: "world-trust-63da5.firebasestorage.app",
    messagingSenderId: "429001435620",
    appId: "1:429001435620:web:6a7abe4c3e394521903ff1",
    measurementId: "G-987861YSYB",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();

  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(
        registerInfo.password
      );
      const hasLetter = /[a-zA-Z]/.test(registerInfo.password);

      if (!hasSpecialChar || !hasLetter) {
        Swal.fire({
          icon: "error",
          title: "Invalid Password",
          text: "Password must contain at least one special character and one letter",
        });
        return;
      }
      const data = await axiosClient.post(`/users/register`, registerInfo);
      if (!data.data.error) {
        localStorage.setItem("email", registerInfo.email);
        navigate("/verification");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const userData = {
        email: user.email,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ").slice(1).join(" "),
        profile_image: user.photoURL,
        signup_type: "2",
      };

      const response = await axiosClient.post(
        "/users/social-media-register",
        userData
      );
      if (response.data && !response.data.error) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome to WorldTrust!",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          navigate("/");
        });
      } else {
        throw new Error(response.data.message || "Failed to login with Google");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Google Login Failed",
        text:
          error.message ||
          "An error occurred during Google login. Please try again.",
      });
    }
  };

  const handleFacebookLogin = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;
      const userData = {
        email: user.email,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ").slice(1).join(" "),
        profile_image: user.photoURL,
        signup_type: "1",
      };

      const response = await axiosClient.post(
        "/users/social-media-register",
        userData
      );
      if (response.data && !response.data.error) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome to WorldTrust!",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          navigate("/");
        });
      } else {
        throw new Error(
          response.data.message || "Failed to login with Facebook"
        );
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Facebook Login Failed",
        text:
          error.message ||
          "An error occurred during Facebook login. Please try again.",
      });
    }
  };

  return (
    <>
      <div className="container-fluid vh-100 d-flex justify-content-center login-container">
        <div className="row w-100 h-100">
          {/* Left side image  */}
          <div className="col-md-6 d-none d-md-block">
            {/* <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <img src={LogoIcon} alt="WorldTrust" className="img-fluid" />
            <h5 className="mt-3">Helping with Donations</h5>
          </div> */}
          </div>

          {/* Right side login form */}
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center h-100">
            <div className="card p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center sc_lgo_allss">
              <div className="d-flex justify-content-center   ">
                <img src={LogoIcon} alt="WorldTrust" />
              </div>
              <h3 className="text-center">Welcome to WorldTrust</h3>
              <p className="text-center fw-bolder ">
                Continue using email address
              </p>

              <form onSubmit={handleSubmit} className="w-50">
                <div className="form-group mb-2">
                  <label className="mb-1">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Name"
                    value={registerInfo.first_name}
                    onChange={({ target }) =>
                      setRegisterInfo({
                        ...registerInfo,
                        first_name: target.value,
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <label className="mb-1">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    value={registerInfo.email}
                    onChange={({ target }) =>
                      setRegisterInfo({
                        ...registerInfo,
                        email: target.value,
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="mb-1">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    value={registerInfo.password}
                    onChange={({ target }) =>
                      setRegisterInfo({
                        ...registerInfo,
                        password: target.value,
                      })
                    }
                    required
                  />
                </div>
                <div className="d-grid">
                  <button type="submit" className="btn bg-natural btn-block">
                    Register
                  </button>
                </div>
              </form>

              <p className="text-center mt-2 mb-1">
                If you're already registered, <Link to="/login">Login</Link>
              </p>

              <div className="text-center">Or continue with</div>

              <div className="d-flex align-items-center gap-2 mt-3 both_partss">
                <button
                  className="btn btn-danger mr-2"
                  onClick={handleGoogleLogin}
                >
                  Use My Google Account
                </button>
                <button
                  className="btn btn-facebook"
                  onClick={handleFacebookLogin}
                >
                  Use My Facebook Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
