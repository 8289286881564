import "./App.css";
import "./bootstrap.css";
import "./assets/css/Main.css";
import "./responsee.css";
// import 'sweetalert2/src/sweetalert2.scss'

import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  json,
} from "react-router-dom";

import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import BlogsDetails from "./pages/BlogsDetails";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import AboutUs from "./pages/AboutUs";
import PayByBank from "./pages/PayByBank";
import PayByCard from "./pages/PayByCard";
import DonationSuccessful from "./pages/DonationSuccessful";
import Login from "./pages/Login";
import Donate from "./pages/Donation/Donate";
import DonateDetails from "./pages/Donation/DonateDetails";
import Events from "./pages/Events/Events";
import MyEvents from "./pages/MyEvents";
import AddReview from "./pages/AddReview";
import EventsDetails from "./pages/Events/EventsDetails";
import FAQs from "./pages/FAQs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndCondtions";
import Careers from "./pages/Careers";
import CareersMessage from "./pages/CareersMessage";
import Profile from "./pages/Profile";
import Kyc from "./pages/Kyc";
import TaxInformation from "./pages/TaxInformation";
import Impact from "./pages/Impact";
import Gratitude from "./pages/Gratitude";
import GratitudeSystem from "./pages/GratitudeSystem";
import TransactionHistory from "./pages/TransactionHistory";
import Referral from "./pages/Referral";
import ReferralList from "./pages/ReferralList";
import Settings from "./pages/Settings";
import Register from "./pages/AuthPages/Register";
import AuthScreen from "./pages/AuthPages/AuthScreen";
import VerificationPage from "./pages/AuthPages/VerificationPage";
import ForgotPasswordPage from "./pages/AuthPages/ForgotPasswordPage";
import PassswordMessage from "./pages/AuthPages/PassswordMessage";
import SetPassword from "./pages/AuthPages/SetPassword";
import AuthRoutes from "./Routes/AuthRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import DonationCategory from "./pages/Donation/DonationCategory";
import Campaign from "./pages/Campaign";
import Wallet from "./pages/Wallet";
const public_key = process.env.REACT_APP_PUBLIC_KEY;
// Initialize Stripe with your publishable key
const stripePromise = loadStripe(public_key);
function App() {
  const location = useLocation();
  const [userData, setUserData] = useState({});
  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userData")));
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      {/* <Header /> */}
      <Routes>
        <Route element={<AuthRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/auth-screen" element={<AuthScreen />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verification" element={<VerificationPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<PassswordMessage />} />
          <Route path="/set-password" element={<SetPassword />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route index element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/donate-category/:id" element={<DonationCategory />} />
          <Route path="/donate-details/:page_url" element={<DonateDetails />} />
          <Route
            path="/pay-by-bank/:page_url"
            element={
              <Elements stripe={stripePromise}>
                <PayByBank />
              </Elements>
            }
          />
          <Route
            path="/pay-by-card"
            element={
              <Elements stripe={stripePromise}>
                <PayByCard />
              </Elements>
            }
          />
          <Route path="/donation-successful" element={<DonationSuccessful />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/events" element={<Events />} />
          <Route path="/add-review/:id" element={<AddReview />} />
          <Route path="/gratitude" element={<Gratitude />} />
          <Route path="/gratitude-system" element={<GratitudeSystem />} />

          <Route path="/referral-list" element={<ReferralList />} />
          <Route path="/events-details/:page_url" element={<EventsDetails />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careers-message/:id" element={<CareersMessage />} />

          {userData && userData?.id && (
            <>
              <Route path="/profile" element={<Profile />} />
              <Route path="/kyc" element={<Kyc />} />
              <Route path="/tax-information" element={<TaxInformation />} />
              <Route path="/impact" element={<Impact />} />
              <Route path="/my-events" element={<MyEvents />} />
              <Route path="/campaign" element={<Campaign />} />
              <Route
                path="/transaction-history"
                element={<TransactionHistory />}
              />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/referral" element={<Referral />} />
              <Route path="/settings" element={<Settings />} />
            </>
          )}

          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs-details" element={<BlogsDetails />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;
