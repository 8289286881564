export const TimeAgo = (timestamp) => {
  const now = new Date();
  const past = new Date(timestamp);
  const diff = now - past;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30); // Approximation: 30 days per month

  if (months >= 1) return `${months} months ago`;
  if (days >= 1) return `${days} days ago`;
  if (hours >= 1) return `${hours} hours ago`;
  if (minutes >= 1) return `${minutes} minutes ago`;
  return `${seconds} seconds ago`;
};

