import React from "react";
import parse from "html-react-parser";

const HtmlWithLimit = ({ htmlString, limit }) => {
  const truncateHtml = (html, limit) => {
    // Create a temporary div element to manipulate the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Traverse through the nodes to get the text content
    let truncatedText = "";
    let charCount = 0;

    const traverseNodes = (node) => {
      // Stop once the character limit is reached
      if (charCount >= limit) return;

      if (node.nodeType === Node.TEXT_NODE) {
        // Get the text part of each node and add it up to the limit
        const remainingChars = limit - charCount;
        const text = node.textContent.slice(0, remainingChars);
        truncatedText += text;
        charCount += text.length;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        truncatedText += `<${node.tagName.toLowerCase()}>`;

        node.childNodes.forEach(traverseNodes);

        truncatedText += `</${node.tagName.toLowerCase()}>`;
      }
    };

    tempDiv.childNodes.forEach(traverseNodes);
    return truncatedText + (charCount >= limit ? "..." : "");
  };

  const limitedHtml = truncateHtml(htmlString, limit);

  return <div>{parse(limitedHtml)}</div>;
};

export default HtmlWithLimit;
