import { Pagination , PaginationItem } from "@mui/material";
import React from "react";

const CommanPagination = ({
  currentPage,
  setCurrentPage,
  limit,
  setLimit,
  totalCounts,
  totalPage
}) => {


  // Calculate the range of entries being shown
  const startEntry = (currentPage - 1) * limit + 1;
  const endEntry = Math.min(currentPage * limit, totalCounts);

  // Handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  return (
    <div className="pages_listst">
      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="list_cnts">
            {totalCounts > 0
              ? `Showing ${startEntry} to ${endEntry} of ${totalCounts} entries`
              : "No entries available"}
          </div>
        </div>
        <div className="col-lg-6">
          <Pagination
            count={totalPage}
            page={currentPage}
            limit={limit}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                {...item}
                // Customizing the text for 'Previous' and 'Next' buttons
                components={{
                  previous: () => <span>Previous</span>,
                  next: () => <span>Next</span>,
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CommanPagination;
