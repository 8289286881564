import { Link } from "react-router-dom";
import { React, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import CountUp from "react-countup";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { Modal, Button } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import "animate.css";
import ProfileSidebar from "./common/ProfileSidebar";
import { Pagination } from "@mui/material";
import CommanPagination from "./common/CommanPagination/CommanPagination";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";
import dayjs from "dayjs";

const GratitudeSystem = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [gratitudeData, setGratitudeData] = useState();

  const getAllGratitude = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(
        `/users/getMeritListing?language=en&pageSize=${limit}&pageNo=${currentPage}`
      );
      if (!response.data.error) {
        setLoading(false);

        setGratitudeData(response.data);
      } else {
        setGratitudeData([]);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setGratitudeData([]);
    }
  };

  useEffect(() => {
    getAllGratitude();
  }, [currentPage, limit]);

  return (
    <>
      <div className="all_suport_bg">
        <section className="ser_v_araeea evnetsts profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" /> Gratitude
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>

              <div className="col-lg-9">
                <div className="entnts_tabss profff_eventss profilss">
                  {/* ---------- Mobile View ---------- */}
                  <div className="row mb_view_show">
                    <div className="col-lg-3">
                      <div className="badge_mg">
                        <img src="/images/badge_big.png" alt="" />
                      </div>
                    </div>
                  </div>
                  {/* ---------- Mobile View ---------- */}

                  <h4 className="br_space">Merit Points</h4>
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="guedd_pointts">
                        <img src="/images/gued_sysy.png" alt="" />
                        <div className="merit_pointgud">
                          <div className="pnt_logos">
                            <img src="/images/do_bg_logo.png" alt="" />
                          </div>
                          <div className="cnt_textxt">
                            <h2>{gratitudeData?.MeritPoints}</h2>
                            <p>Merit Points</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 mb_view_hide">
                      <div className="badge_mg">
                        <img src="/images/badge_big.png" alt="" />
                      </div>
                    </div>
                  </div>

                  <h4 className="br_space">Transaction List</h4>
                  {loading ? (
                    <div className="member-loader">
                      <BeatLoader color="#097C81" />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div class="table-responsive mt-2">
                        <table class="table table-striped al_suport_items mb-0">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Event / Campaign</th>
                              <th>Date</th>
                              <th>Merit Points</th>
                              <th>Rating</th>
                              <th className="last">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {gratitudeData &&
                              gratitudeData?.data?.rows?.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.related_name}</td>
                                    <td>
                                      {dayjs(item.created_at).format(
                                        "DD, MMM YYYY HH:mm:ss A"
                                      )}
                                    </td>
                                    <td>{item.points}</td>
                                    <td className="goldan_tx_w flexx">
                                      {item.status}
                                      <img src="/images/star_gld.png" alt="" />
                                    </td>
                                    <td className="green_tx_w">
                                      {item.statusName}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      <CommanPagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        limit={limit}
                        setLimit={setLimit}
                        totalCounts={gratitudeData?.data?.totalElement}
                        totalPage={gratitudeData?.data?.totalPage}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default GratitudeSystem;

// <Tabs>
//   <div className="tabs_list prof_eventss" id="al_tbs_neww">
//     <div className="tbdsa">
//       <TabList>
//         <Tab>All({gratitudeData?.data?.totalElement})</Tab>
//         <Tab>Donated</Tab>
//       </TabList>
//     </div>
//   </div>

//   <div className="content-wrapper">
//     <div class="table-responsive mt-2">
//       <table class="table table-striped al_suport_items mb-0">
//         <thead>
//           <tr>
//             <th>Sr. No.</th>
//             <th>Event / Campaign</th>
//             <th>Date</th>
//             <th>Merit Points</th>
//             <th>Rating</th>
//             <th className="last">Status</th>
//           </tr>
//         </thead>
//         <tbody>
//           {gratitudeData &&
//             gratitudeData?.data?.rows?.map((item, i) => {
//               return (
//                 <tr key={i}>
//                   <td>{item.id}</td>
//                   <td>{item.related_name}</td>
//                   <td>{formateDate(item.created_at)}</td>
//                   <td>{item.points}</td>
//                   <td className="goldan_tx_w flexx">
//                     {item.status}
//                     <img src="/images/star_gld.png" alt="" />
//                   </td>
//                   <td className="green_tx_w">{item.statusName}</td>
//                 </tr>
//               );
//             })}
//         </tbody>
//       </table>
//     </div>
//     <CommanPagination
//       currentPage={currentPage}
//       setCurrentPage={setCurrentPage}
//       limit={limit}
//       setLimit={setLimit}
//       totalCounts={gratitudeData?.data?.totalElement}
//       totalPage={gratitudeData?.data?.totalPage}
//     />
//     <TabPanel>
//       <div class="table-responsive mt-2">
//         <table class="table table-striped al_suport_items mb-0">
//           <thead>
//             <tr>
//               <th>Sr. No.</th>
//               <th>Event / Campaign</th>
//               <th>Date</th>
//               <th>Merit Points</th>
//               <th>Rating</th>
//               <th className="last">Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             {gratitudeData &&
//               gratitudeData?.data?.rows?.map((item, i) => {
//                 return (
//                   <tr key={i}>
//                     <td>{item.id}</td>
//                     <td>{item.related_name}</td>
//                     <td>{formateDate(item.created_at)}</td>
//                     <td>{item.points}</td>
//                     <td className="goldan_tx_w flexx">
//                       {item.status}
//                       <img src="/images/star_gld.png" alt="" />
//                     </td>
//                     <td className="green_tx_w">{item.statusName}</td>
//                   </tr>
//                 );
//               })}
//           </tbody>
//         </table>
//       </div>
//       <CommanPagination
//         currentPage={currentPage}
//         setCurrentPage={setCurrentPage}
//         limit={limit}
//         setLimit={setLimit}
//         totalCounts={gratitudeData?.data?.totalElement}
//         totalPage={gratitudeData?.data?.totalPage}
//       />
//     </TabPanel>
//     <TabPanel>
//       <div class="table-responsive mt-2">
//         <table class="table table-striped al_suport_items mb-0">
//           <thead>
//             <tr>
//               <th>Sr. No.</th>
//               <th>Event / Campaign</th>
//               <th>Date</th>
//               <th>Merit Points</th>
//               <th>Rating</th>
//               <th className="last">Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             {gratitudeData &&
//               gratitudeData?.data?.rows?.map((item, i) => {
//                 return (
//                   <tr key={i}>
//                     <td>{item.id}</td>
//                     <td>{item.related_name}</td>
//                     <td>{formateDate(item.created_at)}</td>
//                     <td>{item.points}</td>
//                     <td className="goldan_tx_w flexx">
//                       {item.status}
//                       <img src="/images/star_gld.png" alt="" />
//                     </td>
//                     <td className="green_tx_w">{item.statusName}</td>
//                   </tr>
//                 );
//               })}
//           </tbody>
//         </table>
//       </div>
//       <CommanPagination
//         currentPage={currentPage}
//         setCurrentPage={setCurrentPage}
//         limit={limit}
//         setLimit={setLimit}
//         totalCounts={gratitudeData?.data?.totalElement}
//         totalPage={gratitudeData?.data?.totalPage}
//       />
//     </TabPanel>
//   </div>
// </Tabs>;
