import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProfileSidebar from "./common/ProfileSidebar";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import ImageUploader from "./common/ImageUploader";
import { ClipLoader } from "react-spinners";

const Careers = () => {
  const [loading, setLoading] = useState(false);
  const [userNewData, setUserNewData] = useState({});
  const [nidDocImage, setNidDocImage] = useState("");
  const [drivingImage, setDrivingImage] = useState("");
  const [passportImage, setPassportImage] = useState("");
  const [taxData, setTaxData] = useState({
    month_income: "",
    yearly_income: "",
    income_source: "",
    nid_doc_image: "",
    driving_image: "",
    passport_image: "",
  });

  const [errors, setErrors] = useState({
    month_income: "",
    yearly_income: "",
    income_source: "",
  });

  const handleProfile = async () => {
    const response = await axiosClient.get("/users/getByID");
    if (!response.data.error) {
      localStorage.setItem("userData", JSON.stringify(response.data.data));
    }
  };
  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors before validation
    setErrors({
      month_income: "",
      yearly_income: "",
      income_source: "",
    });

    let formIsValid = true;
    let newErrors = {};

    // Validate monthly income
    if (!taxData.month_income) {
      formIsValid = false;
      newErrors.month_income = "Monthly income is required";
    }

    // Validate yearly income
    if (!taxData.yearly_income) {
      formIsValid = false;
      newErrors.yearly_income = "Annual income is required";
    }

    // Validate income source
    if (!taxData.income_source) {
      formIsValid = false;
      newErrors.income_source = "Income source is required";
    }

    // Update error state
    setErrors(newErrors);

    // If form is valid, proceed with submission (you can add your submission logic here)
    if (formIsValid) {
      try {
        setLoading(true);
        const upadatedData = await axiosClient.put("users/update-user-tax", {
          ...taxData,
          nid_doc_image: nidDocImage,
          driving_image: drivingImage,
          passport_image: passportImage,
        });
        if (!upadatedData.data.error) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "User tax update successful",
          });
          handleProfile();
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setUserNewData(JSON.parse(localStorage.getItem("userData")));
  }, []);

  useEffect(() => {
    setTaxData({
      ...taxData,
      month_income: userNewData?.month_income,
      yearly_income: userNewData?.yearly_income,
      income_source: userNewData?.income_source,
      nid_doc_image: userNewData?.nid_doc_image,
      driving_image: userNewData?.driving_image,
      passport_image: userNewData?.passport_image,
    });
    setNidDocImage(userNewData?.nid_doc_image);
    setDrivingImage(userNewData?.driving_image);
    setPassportImage(userNewData?.passport_image);
  }, [userNewData]);

  return (
    <div className="all_suport_bg">
      <section className="ser_v_araeea profiles">
        <div className="container">
          <div className="bradcams mb_view_hide">
            <span className="bothss">
              <Link to="">My Account</Link>{" "}
              <img src="/images/brd_rowwo.png" alt="" /> Profile
            </span>
          </div>
          <div className="row">
            <div className="col-lg-3 mb_view_hide">
              <ProfileSidebar />
            </div>

            <div className="col-lg-9">
              <div className="form_boxx profilss">
                <div className="form_arar mt-0">
                  <h4>Tax Information</h4>
                  <div className="mang_und_listst mt-0" id="two_arts_prt">
                    <form action="" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Monthly Income</label>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.month_income ? "input-error" : ""
                              }`}
                              name="month_income"
                              value={taxData.month_income}
                              // onChange={(e) => {
                              // setErrors({
                              //   ...errors,
                              //   month_income: "",
                              // });
                              // setTaxData({
                              //   ...taxData,
                              //   month_income: e.target.value,
                              // });
                              // }
                              // }}
                              onChange={(e) => {
                                let value = e.target.value;
                                const regex = /^(\d{1,6})(\.\d{0,2})?$/;

                                if (value === "" || regex.test(value)) {
                                  setErrors({
                                    ...errors,
                                    month_income: "",
                                  });
                                  setTaxData({
                                    ...taxData,
                                    month_income: value,
                                  });
                                }
                              }}
                              placeholder="Enter your monthly income"
                            />
                            {errors.month_income && (
                              <div className="error">{errors.month_income}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Annual Income</label>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.yearly_income ? "input-error" : ""
                              }`}
                              name="yearly_income"
                              value={taxData.yearly_income}
                              onChange={(e) => {
                                let value = e.target.value;
                                const regex = /^(\d{1,6})(\.\d{0,2})?$/;
                                if (value === "" || regex.test(value)) {
                                  setErrors({
                                    ...errors,
                                    yearly_income: "",
                                  });
                                  setTaxData({
                                    ...taxData,
                                    yearly_income: value,
                                  });
                                }
                              }}
                              placeholder="Enter your annual income"
                            />
                            {errors.yearly_income && (
                              <div className="error">
                                {errors.yearly_income}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Income Source</label>
                            <input
                              type="text"
                              placeholder="Enter your income source"
                              className={`form-control ${
                                errors.income_source ? "input-error" : ""
                              }`}
                              name="income_source"
                              value={taxData.income_source}
                              onChange={(e) => {
                                setErrors({
                                  ...errors,
                                  income_source: "",
                                });
                                setTaxData({
                                  ...taxData,
                                  income_source: e.target.value,
                                });
                              }}
                            />
                            {errors.income_source && (
                              <div className="error">
                                {errors.income_source}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4 justy_centr">
                        <div className="col-lg-4 col-6">
                          <div className="mgs_titales">
                            <h6 className="titaless">NID Image</h6>
                            <div id="imgss_upload">
                              <ImageUploader
                                setState={setNidDocImage}
                                defaultImage={nidDocImage}
                              />
                            </div>
                            <p className="t_sizes">
                              Recommended image size 150x150
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-6">
                          <div className="mgs_titales">
                            <h6 className="titaless">Driving License Image</h6>
                            <div id="imgss_upload">
                              <ImageUploader
                                setState={setDrivingImage}
                                defaultImage={drivingImage}
                              />
                            </div>
                            <p className="t_sizes">
                              Recommended image size 150x150
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-6">
                          <div className="mgs_titales">
                            <h6 className="titaless">Passport Image</h6>
                            <div id="imgss_upload">
                              <ImageUploader
                                setState={setPassportImage}
                                defaultImage={passportImage}
                              />
                            </div>
                            <p className="t_sizes">
                              Recommended image size 150x150
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-12event mt-5">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="sub_mitess"
                              disabled={loading}
                            >
                              {loading ? (
                                <ClipLoader color="#fff" size={"15"} />
                              ) : (
                                " Update Tax Information"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Careers;
