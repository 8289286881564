import { Link } from "react-router-dom";
import {React, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import CountUp from 'react-countup';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Modal, Button } from 'react-bootstrap'
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css';
import ProfileSidebar from "./common/ProfileSidebar";
import CommanPagination from "./common/CommanPagination/CommanPagination";




const Campaign = () => {
	const [show, setShow] = useState(false);
	const [showscan, setShowscan] = useState(false);
	const handleClose = () => setShow(false);
	const videoshow = () => setShow(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [gratitudeData, setGratitudeData] = useState();

	const handleClose1 = () => setShowscan(false);
	const cameracl = () => setShowscan(true);

    return (
        <>
	
<Modal className="all_supportss sm_sizess" id="registeress" show={show} onHide={handleClose}>
  <Modal.Header closeButton></Modal.Header>
  <Modal.Body>
	<div className="fromss_boxx">
		<div className="titalss">
		 <h3>Register Now</h3>
		</div>
		<form action="">
		 <div className="unfildss" id="rediiobnt">
			<p>Register now for this event as :</p>
			<div className="rdo_boxx">
			<p>
				<input type="radio" id="test1" name="radio-group" checked />
				<label for="test1">Participant</label>
			</p>
			<p>
				<input type="radio" id="test2" name="radio-group" />
				<label for="test2">Volunteer</label>
			</p>

			</div>
		 </div>
		 <div class="form-group">
			 <label>Name<span className="red">*</span></label>
			 <input type="text" class="form-control" name="" placeholder="Enter Name" />
		 </div>

		 <div class="form-group">
			 <label>Email ID<span className="red">*</span></label>
			 <input type="text" class="form-control" name="" placeholder="Enter Email ID" />
		 </div>

		 <div class="form-group">
			 <label>Phone Number<span className="red">*</span></label>
			 <input type="text" class="form-control" name="" placeholder="Enter Phone Number" />
		 </div>

		 {/* <div class="form-group check_txt" id="checkox">
		    <input id="check1" name="" type="checkbox" value="yes" />			
			<label for="check1">I confirm my phone number</label>			
		 </div> */}

		 <div class="form-group">
		  <div className="ck_titalss">	
		   <h3>Choose your best way for communication</h3>
		  </div>
		  <div className="row">
			<div className="col-lg-4 col-4">
			<div className="check_txtx" id="checkox">
		     <input id="check2" name="" type="checkbox" value="yes" />			
			 <label for="check2">Whatsapp</label>			
		    </div>
			</div>

			<div className="col-lg-4 col-4">
			<div className="check_txtx" id="checkox">
		     <input id="check3" name="" type="checkbox" value="yes" />			
			 <label for="check3">Phone Call</label>			
		    </div>
			</div>

			<div className="col-lg-4 col-4">
			<div className="check_txtx" id="checkox">
		     <input id="check4" name="" type="checkbox" value="yes" />			
			 <label for="check4">Email</label>			
		    </div>
			</div>
		  </div>
		 </div> 

		 <div class="form-group mt-4">
			<button type="submit" value="Register" class="sub_mitess">Register</button>
		 </div>
		</form>
	</div>
  </Modal.Body>
</Modal>

<Modal className="all_supportss sm_sizess" id="ScanUpload1" show={showscan} onHide={handleClose1}>
  <Modal.Header closeButton></Modal.Header>
  <Modal.Body>
	<div className="fromss_boxx">
		<div className="titalss">
		 <h3>Scan or Upload QR Code</h3>
		</div>

		<div className="cm_box_area scan_fildss">
			<div className="cm_mgs"><img src="/images/camera.png" alt="" /></div>
			<div className="cm_bntss"><Link to="">Request Camera Permission</Link></div>
			<p><Link to="">Scan an image file</Link></p>
		</div>


		<div className="cm_box_area scan_fildss choo_im_img">
			<div className="cm_mgs"><img src="/images/camera.png" alt="" /></div>
			<div className="cm_bntss">
				<Link to="">Choose image - No image choosen</Link>
				<p>or drop an image to scan</p>
			</div>
			<p>scan using camera directly</p>
		</div>

		<div className="cm_box_area scan_fildss sc_blak_filess">
			<div className="cm_mgs"><img src="/images/scan_bg.png" alt="" /></div>
			<p><Link to="">Stop Scanning</Link></p>
		</div>
	
	</div>
  </Modal.Body>
</Modal>

	<div className="all_suport_bg">
	<section className="ser_v_araeea evnetsts profiles">
		<div className="container">
		<div className="bradcams mb_view_hide"><span className="bothss"><Link to="">My Account</Link>  <img src="/images/brd_rowwo.png" alt="" />  Campaingns</span></div>
		<div className="row">
                    <div className="col-lg-3 mb_view_hide">
                      {/* <div className="my_profiless">
                        <div className="top_pro_ct_mg">
                         <div className="pro_imgs"><img src="/images/my_profl.png" alt="" /></div>
                         <h4>Jackson(MU5785)</h4>
                         <p>jacksoncomp@gmail.com</p>
                        </div>

                        <ul className="profile_menus">
                           <li><Link to="/impact"><img src="/images/side_m_ic1.png" alt="" /> Impact</Link></li>
                           <li><Link to="/Profile"><img src="/images/side_m_ic2.png" alt="" /> Profile</Link></li>
                           <li><Link to="/kyc"><img src="/images/side_m_ic10.png" alt="" /> KYC</Link></li>
                           <li><Link to="/tax-information"><img src="/images/side_m_ic11.png" alt="" /> Tax Information</Link></li>
                           <li><Link to="/Gratitude"><img src="/images/side_m_ic3.png" alt="" /> Gratitude</Link></li>
                           <li><Link to="/my-events"><img src="/images/side_m_ic4.png" alt="" /> Events</Link></li>
                           <li><Link to="campaigns" className="act"><img src="/images/side_m_ic5.png" alt="" /> Campaigns</Link></li>
                           <li><Link to="/transaction-history"><img src="/images/side_m_ic6.png" alt="" /> Transactions</Link></li>
                           <li><Link to="/referral"><img src="/images/side_m_ic7.png" alt="" /> Referral</Link></li>
                           <li><Link to="/settings"><img src="/images/side_m_ic8.png" alt="" /> Settings</Link></li> 
                           <li><Link to=""><Button variant="primary" onClick={videoshow}><img src="/images/side_m_ic9.png" alt="" /> Logout</Button></Link></li>
                        </ul>

                        <div className="merit_point">
                         <div className="pnt_logos"><img src="/images/point_logo.png" alt="" /></div>
                         <div className="cnt_textxt">
                            <h2>400</h2>
                            <p>Merit Points</p>
                         </div>
                        </div>


						<div className="scanss">
						 <div className="scansses">							
							<Button variant="primary" onClick={cameracl}><h5>Scan Now <span className="scaan"><img src="/images/scanss.png" alt="" /></span></h5></Button>
						 </div> 
						 <p>Note : scan now for your <span> events/donations</span></p>
						</div>

                      </div> */}
					  <ProfileSidebar/>
                    </div>
					<div className="col-lg-9">
						<div className="entnts_tabss profff_eventss compearss">
							<h4 className="mb-4">
								Campaigns (09) <span className="ad_linksss"><Link to="/add-campaign"> Create New Campaign</Link></span>
							</h4>

							<div className="content-wrapper">
								<div className="main_tabs_data mb-3">
									<div className="un_data_list" id="cam_list_parts">
										<Link to=""><div className="usr_mgss"><img src="/images/events_sm.png" alt="" /></div></Link>
										<div className="cntent_tx">
											<div className="cam_dtl_contents">
												<p><strong>Title :</strong> Emergency Funding for abandones children near hill</p>
												<p><strong>Featured :</strong> No</p>
												<p><strong>Goal :</strong> $2,000,000,000</p>
												<p><strong>Raised :</strong> $0</p>
												<p><strong>Created At :</strong> 18-Apr-2024</p>
											</div>

											<div className="cam_adress">
												<div className="d_lists">
													<ul>
														<li>
															<Link to=""> <img src="/images/edit_views.png" alt="" /></Link>
														</li>
														<li>
															<Link to=""> <img src="/images/eye_views.png" alt="" /></Link>
														</li>
														<li>
															<Link to=""> <img src="/images/ic_delets.png" alt="" /></Link>
														</li>
													</ul>
												</div>

												<div className="ac_tvity green_tx_w">Verified</div>
											</div>
										</div>
									</div>
								</div>

								<div className="main_tabs_data mb-3">
									<div className="un_data_list" id="cam_list_parts">
										<Link to=""><div className="usr_mgss"><img src="/images/events_sm1.png" alt="" /></div></Link>
										<div className="cntent_tx">
											<div className="cam_dtl_contents">
												<p><strong>Title :</strong> Emergency Funding for abandones children near hill</p>
												<p><strong>Featured :</strong> No</p>
												<p><strong>Goal :</strong> $2,000,000,000</p>
												<p><strong>Raised :</strong> $0</p>
												<p><strong>Created At :</strong> 18-Apr-2024</p>
											</div>

											<div className="cam_adress">
												<div className="d_lists">
													<ul>
														<li>
															<Link to=""> <img src="/images/edit_views.png" alt="" /></Link>
														</li>
														<li>
															<Link to=""> <img src="/images/eye_views.png" alt="" /></Link>
														</li>
														<li>
															<Link to=""> <img src="/images/ic_delets.png" alt="" /></Link>
														</li>
													</ul>
												</div>

												<div className="ac_tvity goldan_tx_w">Inprocess</div>
											</div>
										</div>
									</div>
								</div>

								<div className="main_tabs_data mb-3">
									<div className="un_data_list" id="cam_list_parts">
										<Link to=""><div className="usr_mgss"><img src="/images/events_sm3.png" alt="" /></div></Link>
										<div className="cntent_tx">
											<div className="cam_dtl_contents">
												<p><strong>Title :</strong> Emergency Funding for abandones children near hill</p>
												<p><strong>Featured :</strong> No</p>
												<p><strong>Goal :</strong> $2,000,000,000</p>
												<p><strong>Raised :</strong> $0</p>
												<p><strong>Created At :</strong> 18-Apr-2024</p>
											</div>

											<div className="cam_adress">
												<div className="d_lists">
													<ul>
														<li>
															<Link to=""> <img src="/images/edit_views.png" alt="" /></Link>
														</li>
														<li>
															<Link to=""> <img src="/images/eye_views.png" alt="" /></Link>
														</li>
														<li>
															<Link to=""> <img src="/images/ic_delets.png" alt="" /></Link>
														</li>
													</ul>
												</div>

												<div className="ac_tvity red_tx_w">Pending</div>
											</div>
										</div>
									</div>
								</div>

								{/* <div className="pages_listst">
						<div className="row">
							<div className="col-lg-6 col-12">
								<div className="list_cnts">Showing 1 to 9 of 5 entries</div>
							</div>
							<div className="col-lg-6 col-12">
							<div className="paginationss">
											<ul>
											<li><Link to="">Previous</Link></li>	
											<li><Link to="" className="act">1</Link></li>
											<li><Link to="">2</Link></li>
											<li><Link to="">3</Link></li>	
											<li><Link to="">4</Link></li>
											<li><Link to="">Next </Link></li>	
											</ul>
										</div>			
							</div>
						</div>
								</div> */}
								<CommanPagination
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
									limit={limit}
									setLimit={setLimit}
									totalCounts={gratitudeData?.data?.totalElement}
								/>
							</div>
						</div>
					</div>

		  </div>

		
		</div>
	</section>
</div>


	</>
    )
};

export default Campaign;