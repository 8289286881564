import { Link } from "react-router-dom";
import { Accordion, AccordionItem } from '@szhsin/react-accordion';

const Footer = () => {
    return (
        <>
<footer id='Footer_arae'>  
  <div className="ftr_manages">  
    <div className='container'>
        <div className='row brd_managess'>
            <div className='col-lg-3 mb-4 mb_view_show'>
                <div className='ftr_partss adss_flss logoss'>
                    <div className='ftr_logoss'><img src='/images/ftr_logo.png' alt='' /></div>
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='subss_cr'>
                    <h4>Sign up for our newsletter</h4>
                    <div className='stay_up_w'>
                        <form action=''>
                            <input type='text' name='email' className='form-control' id='subs_email' value='' placeholder='Your email address' required='' />
                            <button type='submit' value='Submit' className='sub_mitess'><img src='/images/ar_w-right.png' alt='' /></button>
                        </form>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 mb_view_hide'>
                <div className='social_link_als'>
                    <div className='un_socials_lnks'>
                        <ul>
                            <li>
                                <Link to='/'><img src='/images/social_ic1.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='/images/social_ic2.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='/images/social_ic3.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='/images/social_ic4.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='/images/social_ic5.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='/images/social_ic6.png' alt='' /></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col-lg-3 mb_view_hide mb-4'>
                <div className='ftr_partss adss_flss logoss'>
                    <div className='ftr_logoss'><img src='/images/ftr_logo.png' alt='' /></div>
                </div>
            </div>

            <div className='col-lg-3 mb_view_hide'>
                <div className='ftr_partss'>
                    <h4>Worldtrust</h4>
                    <ul>
                        <li><Link to='/'>Worldtrust Category</Link></li>
                        <li><Link to='/'>Team Worldtrust</Link></li>
                        <li><Link to='/'>Worldtrust News </Link></li>
                        <li><Link to='/'>Sign Up as a charity</Link></li>
                    </ul>
                </div>
            </div>

            <div className='col-lg-3 mb_view_hide'>
                <div className='ftr_partss'>
                    <h4>About</h4>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/about-us'>About Us</Link></li>
                        <li><Link to='/donate'>Donate</Link></li>
                        <li><Link to='/'>Resources</Link></li>
                        <li><Link to='/contact'>Contact Us</Link></li>
                    </ul>
                </div>
            </div>

            <div className='col-lg-3 mb_view_hide'>
                <div className='ftr_partss'>
                    <h4>Useful Links</h4>
                    <ul>
                        <li><Link to='/events'>Events</Link></li>
                        <li><Link to='/faqs'>FAQ</Link></li>
                        <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                        <li><Link to='/terms-and-conditions'>Terms & Condition</Link></li>
                        <li><Link to='/careers'>Careers</Link></li>
                    </ul>
                </div>
            </div>


            <div className="col-lg-12">
            <div className="accordion_container mb_view_show">	
            <div className='ftr_partss'>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/about-us'>About Us</Link></li>
                        <li><Link to='/donate'>Donate</Link></li>
                        <li><Link to='/'>Resources</Link></li>
                        <li><Link to='/contact'>Contact Us</Link></li>
                    </ul>
                </div>

		    <Accordion>
			  <AccordionItem header="Useful Links" >
              <div className='ftr_partss'>
                    <ul>
                        <li><Link to=''>Useful Links1</Link></li>
                        <li><Link to=''>Useful Links2</Link></li>
                    </ul>
                </div>
			  </AccordionItem>
			</Accordion>
           </div>
        </div>

        <div className='col-lg-6 mb_view_show'>
                <div className='social_link_als'>
                    <div className='un_socials_lnks'>
                        <ul>
                            <li>
                                <Link to='/'><img src='/images/social_ic1.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='/images/social_ic2.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='/images/social_ic3.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='/images/social_ic4.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='/images/social_ic5.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='/images/social_ic6.png' alt='' /></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <div className='eml_socilal'>
            <div className='em_ico'>Copyright © 2018-2024 www.worldtrust.io All Rights Reserved.</div>
            <div className='social'>
                <div className='socialss'>Designed & Developed by <Link to='/https://www.pearsystem.com/' target='_blank'>PearSystem</Link></div>
            </div>
        </div>
    </div>
    </div>
</footer>

		

        </>
    )
};

export default Footer;