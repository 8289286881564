import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProfileSidebar from "./common/ProfileSidebar";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import ImageUploader from "./common/ImageUploader";
import { ClipLoader } from "react-spinners";

const Careers = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [userNewData, setUserNewData] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    phoneCode: "",
    gender: "",
    dob: startDate,
    referral_code: "",
    profile_image: "",
  });

  const [errorsValidation, setErrorsValidation] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",
  });

  const handleDateChange = (date) => {
    setStartDate(date);
    setProfileData({
      ...profileData,
      dob: date ? date.toISOString().split("T")[0] : "",
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    let formIsValid = true;
    let errors = {};

    // Validation for First Name
    if (!profileData.first_name) {
      formIsValid = false;
      errors.first_name = "First name is required";
    }

    // Validation for Last Name
    if (!profileData.last_name) {
      formIsValid = false;
      errors.last_name = "Last name is required";
    }

    // Validation for Email
    if (!profileData.email) {
      formIsValid = false;
      errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(profileData.email)) {
      formIsValid = false;
      errors.email = "Email address is invalid";
    }

    // Validation for Phone
    if (!profileData.phone) {
      formIsValid = false;
      errors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(profileData.phone)) {
      formIsValid = false;
      errors.phone = "Phone number must be 10 digits";
    }

    // Validation for Gender
    if (!profileData.gender) {
      formIsValid = false;
      errors.gender = "Gender is required";
    }

    // Validation for Date of Birth
    if (!profileData.dob) {
      formIsValid = false;
      errors.dob = "Date of birth is required";
    }

    setErrorsValidation(errors);

    if (formIsValid) {
      try {
        setLoading(true);
        const upadatedData = await axiosClient.put("users/update-user", {
          ...profileData,
          profile_image: profileImage,
        });
        if (!upadatedData.data.error) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "User update successful",
          });
          handleProfile();
 
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }

      // Add your form submission logic here
    }
  };

  const handleProfile = async () => {
    const response = await axiosClient.get("/users/getByID");
    if (!response.data.error) {
      localStorage.setItem("userData", JSON.stringify(response.data.data));
      window.location.reload();
    }
  };

  function runAfterImageDelete(file) {
    console.log({ file });
  }
  useEffect(() => {
    setUserNewData(JSON.parse(localStorage.getItem("userData")));
  }, []);

  useEffect(() => {
    setProfileData({
      first_name: userNewData?.first_name,
      last_name: userNewData?.last_name,
      email: userNewData?.email,
      phone: userNewData?.phone,
      phoneCode: userNewData?.phoneCode,
      gender: userNewData?.gender,
      dob: userNewData?.dob,
      referral_code: userNewData?.referral_code,
      profile_image: userNewData?.profile_image,
    });
    setProfileImage(userNewData?.profile_image);
  }, [userNewData]);

  return (
    <>
      <div className="all_suport_bg">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" /> Profile
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>
              <div className="col-lg-9">
                <div className="form_boxx profilss">
                  <div className="form_arar mt-0">
                    <h4>Profile</h4>
                    <div className="mang_und_listst mt-0">
                      <form action="" onSubmit={handleUpdate}>
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>First Name</label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errorsValidation.first_name
                                        ? "input-error"
                                        : ""
                                    }`}
                                    name="firstName"
                                    value={profileData.first_name}
                                    onChange={(e) => {
                                      setProfileData({
                                        ...profileData,
                                        first_name: e.target.value,
                                      });
                                    }}
                                    placeholder="Enter first name"
                                  />
                                  {errorsValidation.first_name && (
                                    <div className="error">
                                      {errorsValidation.first_name}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Last Name</label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errorsValidation.last_name
                                        ? "input-error"
                                        : ""
                                    }`}
                                    name="last_name"
                                    value={profileData.last_name}
                                    onChange={(e) => {
                                      setProfileData({
                                        ...profileData,
                                        last_name: e.target.value,
                                      });
                                    }}
                                    placeholder="Enter last name"
                                  />
                                  {errorsValidation.last_name && (
                                    <div className="error">
                                      {errorsValidation.last_name}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Email Address</label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errorsValidation.email
                                        ? "input-error"
                                        : ""
                                    }`}
                                    name="email"
                                    value={profileData.email}
                                    onChange={(e) => {
                                      setProfileData({
                                        ...profileData,
                                        email: e.target.value,
                                      });
                                    }}
                                    placeholder="Enter email address"
                                  />
                                  {errorsValidation.email && (
                                    <div className="error">
                                      {errorsValidation.email}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Mobile No.</label>
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      errorsValidation.phone
                                        ? "input-error"
                                        : ""
                                    }`}
                                    name="phone"
                                    value={profileData.phone}
                                    onChange={(e) => {
                                      setProfileData({
                                        ...profileData,
                                        phone: e.target.value,
                                      });
                                    }}
                                    placeholder="Enter phone number"
                                  />
                                  {errorsValidation.phone && (
                                    <div className="error">
                                      {errorsValidation.phone}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Gender</label>
                                  <select
                                    className={`form-control ${
                                      errorsValidation.gender
                                        ? "input-error"
                                        : ""
                                    }`}
                                    name="gender"
                                    value={profileData.gender}
                                    onChange={(e) => {
                                      setProfileData({
                                        ...profileData,
                                        gender: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="">Choose your gender</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                  </select>
                                  {errorsValidation.gender && (
                                    <div className="error">
                                      {errorsValidation.gender}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group relatives">
                                  <label>Date of Birth</label>
                                  <DatePicker
                                    name="dob"
                                    value={profileData.dob}
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    dateFormat="yyyy-MM-dd"
                                  />
                                  {errorsValidation.dob && (
                                    <div className="error">
                                      {errorsValidation.dob}
                                    </div>
                                  )}
                                  <img src="/images/calenders.png" alt="" />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Referral Code</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="referCode"
                                    value={profileData.referral_code}
                                    readOnly
                                    placeholder="Enter referral code"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div id="imgss_upload">
                              <ImageUploader
                                setState={setProfileImage}
                                defaultImage={profileImage}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 mt-4">
                            <div className="form-group">
                              <button
                                type="submit"
                                value="Update Profile"
                                className="sub_mitess"
                                disabled={loading}
                              >
                                {loading ? (
                                  <ClipLoader color="#fff" size={"15"} />
                                ) : (
                                  "Update Profile"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;
