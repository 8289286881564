import { Link } from "react-router-dom";
import { React, useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import axiosClient from "../Services/Axios/axios";

const blogsslider = {
  margin: 0,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const NewsFeaturedStories = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};

const RecentEvents = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};

const formatdate = (created) => {
  const date = new Date(created);
  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  // Return the formatted date with dashes
  return `${day}-${month}-${year}`;
};

const Blogs = () => {
  const [bannerData, setbannerdata] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);

  const getBlogData = async () => {
    const getBannerData = await axiosClient.get(
      "/news/getBannerNewsList?language=en"
    );
    if (!getBannerData.data.error) {
      setbannerdata(getBannerData.data.data);
    }
    const getRecentData = await axiosClient.get(
      "/news/getRecentNewsList?language=en&pageSize=10&pageNo=1"
    );

    if (!getRecentData.data.error) {
      setRecentData(getRecentData.data.data.rows);
    }
    const getUpdateData = await axiosClient.get(
      "/news/getUpdateNewsList?language=en"
    );
    if (!getUpdateData.data.error) {
      setUpdatedData(getUpdateData.data.data);
    }
  };

  useEffect(() => {
    getBlogData();
  }, []);
  return (
    <>
      <div className="all_suport_bg">
        <section className="ser_v_araeea evnt_slisder allss" id="blogss">
          <div className="container">
            <OwlCarousel
              className="slider-items owl-carousel"
              id="home_sliserss"
              {...blogsslider}
            >
              {bannerData?.map((banner, i) => (
                <div key={i} className="item">
                  <div className="sld_partsss">
                    <img src={banner.image} alt="" />
                    <div className="over_cntsst">
                      <h2>{banner.name}</h2>
                      <p>
                        <img src="/images/ic_sharp-location-on.png" alt="" />{" "}
                        Phnom Penh , Combodia
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </section>

        <section className="ser_v_araeea" id="news_featured_hm">
          <div className="container">
            <div class="al_sec_ctxt">
              <h2>News & Featured stories</h2>
            </div>
            <OwlCarousel
              className="slider-items owl-carousel"
              id="three_points"
              {...NewsFeaturedStories}
            >
              <div className="item">
                <div className="news_box_arara">
                  <div className="nes_mg_arae">
                    <img src="/images/news_bg_sm.png" alt="" />
                  </div>
                  <span className="daytss">
                    08-09-2023 <span className="ctgrees">Category</span>
                  </span>
                  <h3>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                    aut natus minima eum Lorem ipsum dolor sit amet. Sed
                    consequatur necessitatibus aut cfbg ......{" "}
                    <Link to="" className="pg_links">
                      know more
                    </Link>
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="news_box_arara">
                  <div className="nes_mg_arae">
                    <img src="/images/news_bg_sm1.png" alt="" />
                  </div>
                  <span className="daytss">
                    08-09-2023 <span className="ctgrees">Category</span>
                  </span>
                  <h3>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                    aut natus minima eum Lorem ipsum dolor sit amet. Sed
                    consequatur necessitatibus aut cfbg ......{" "}
                    <Link to="" className="pg_links">
                      know more
                    </Link>
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="news_box_arara">
                  <div className="nes_mg_arae">
                    <img src="/images/news_bg_sm2.png" alt="" />
                  </div>
                  <span className="daytss">
                    08-09-2023 <span className="ctgrees">Category</span>
                  </span>
                  <h3>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                    aut natus minima eum Lorem ipsum dolor sit amet. Sed
                    consequatur necessitatibus aut cfbg ......{" "}
                    <Link to="" className="pg_links">
                      know more
                    </Link>
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="news_box_arara">
                  <div className="nes_mg_arae">
                    <img src="/images/news_bg_sm1.png" alt="" />
                  </div>
                  <span className="daytss">
                    08-09-2023 <span className="ctgrees">Category</span>
                  </span>
                  <h3>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                    aut natus minima eum Lorem ipsum dolor sit amet. Sed
                    consequatur necessitatibus aut cfbg ......{" "}
                    <Link to="" className="pg_links">
                      know more
                    </Link>
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </section>

        <section className="ser_v_araeea pt-2" id="news_featured_hm">
          <div className="container">
            <div class="al_sec_ctxt">
              <h2>Recent Events</h2>
            </div>
            <OwlCarousel
              className="slider-items owl-carousel"
              id="three_points"
              {...RecentEvents}
            >
              {recentData?.map((res, i) => {
                return (
                  <div className="item">
                    <div className="news_box_arara">
                      <div className="nes_mg_arae">
                        <img src={res.image} alt="" />
                      </div>
                      <span className="daytss">
                        {formatdate(res.created_at)}
                        {res.blog_category && (
                          <span className="ctgrees">
                            {res.blog_category.name}
                          </span>
                        )}
                      </span>
                      <h3>{res.name}</h3>
                      <div
                        className="max-h-185"
                        dangerouslySetInnerHTML={{
                          __html: res.description,
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </section>

        <section className="ser_v_araeea pt-2" id="news_featured_hm">
          <div className="container">
            <div class="al_sec_ctxt mb-0">
              <h2>Updates</h2>
            </div>

            <div className="row">
              {updatedData.map((res, i) => (
                <div className="col-lg-4 mt-5">
                  <div className="news_box_arara">
                    <div className="nes_mg_arae">
                      <img src={res.image} alt="" />
                    </div>
                    <span className="daytss">
                      {formatdate(res.created_at)}
                      {res.blog_category && (
                        <span className="ctgrees">
                          {res.blog_category.name}
                        </span>
                      )}
                    </span>
                    <h3>{res.name}</h3>
                    <div
                      className="max-h-185"
                      dangerouslySetInnerHTML={{
                        __html: res.description,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Blogs;
