import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosClient.interceptors.request.use(
  (config) => {
    const userData = localStorage.getItem("userData");
    const token = JSON.parse(userData);

    if (userData) {
      // Add the Bearer token to the Authorization header
      config.headers.Authorization = `Bearer ${token.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosClient;
