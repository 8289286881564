import { Link, useParams } from "react-router-dom";
import { React, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axiosClient from "../../Services/Axios/axios";
import { TimeAgo } from "../../Utils/DateFormater";
import HtmlWithLimit from "../../Components/Common/HtmlWithLimit";
import { BeatLoader } from "react-spinners";

const similersldd = {
  margin: 0,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const Donate = () => {
  const { page_url } = useParams();
  const [donateData, setDonateData] = useState({});
  const [cateId, setCateId] = useState(0);
  const [similerCamping, setSimilerCamping] = useState([]);
  const [testimonialId, setTestimonialId] = useState(0);
  const [testimonalData, setTestimonalData] = useState([]);
  const [testimonialLimit, setTestimonialLimit] = useState(5);
  const [testimonialCount, setTestimonialCount] = useState(0);
  const [donationLimit, setDonationLimit] = useState(5);
  const [donationCount, setDonationCount] = useState(0);
  const [donationData, setDonationData] = useState([]);
  const [verificationData, setVerificationData] = useState([]);
  const [isExpand, setIsExpand] = useState(0);
  const [loading, setLoading] = useState(false);

  const getSingleEventData = async () => {
    try {
      setLoading(true);
      const data = await axiosClient.get(
        `/campaigns/getCampaignDetailsById/${page_url}?language=en`
      );
      console.log(data.data.data, "data");
      if (!data.data.error) {
        setDonateData(data.data.data);
        setCateId(data.data.data.donation_category_id);
        setTestimonialId(data.data.data.id);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getSimilerCampings = async () => {
    try {
      const data = await axiosClient.get(
        `/campaigns/getCampaignsByCategoryID?language=en&pageSize=3&pageNo=1&category_id=${cateId}`
      );
      if (!data.data.error) {
        setSimilerCamping(data.data.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTestimonialData = async () => {
    try {
      const data = await axiosClient.get(
        `/campaigns/getCampaignTestimonialById/${testimonialId}?language=en&pageSize=${testimonialLimit}&pageNo=1`
      );
      if (!data.data.error) {
        setTestimonalData(data.data.data.rows);
        setTestimonialCount(data.data.data.totalElement);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDonationData = async () => {
    try {
      const data = await axiosClient.get(
        `/campaigns/getCampaignDonationById/${testimonialId}?language=en&pageSize=${donationLimit}&pageNo=1`
      );
      if (!data.data.error) {
        setDonationData(data.data.data.rows);
        setDonationCount(data.data.data.totalElement);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVerificationData = async () => {
    try {
      const data = await axiosClient.get(
        `/campaigns/getCampaignVerification?language=en`
      );
      if (!data.data.error) {
        setVerificationData(data.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleEventData();
    getVerificationData();
  }, [page_url]);
  useEffect(() => {
    if (testimonialId !== 0) {
      getTestimonialData();
    }
  }, [testimonialId, testimonialLimit]);
  useEffect(() => {
    if (testimonialId !== 0) {
      getDonationData();
    }
  }, [testimonialId, donationLimit]);
  useEffect(() => {
    if (cateId !== 0) {
      getSimilerCampings();
    }
  }, [cateId]);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  console.log(
    donateData?.donate_exipre_value === 1 ||
      donateData?.donate_exipre_value2 === 1,
    "donateData"
  );
  return (
    <>
      <div className="all_suport_bg">
        {donateData.id && (
          <section className="ser_v_araeea" id="do_telss_page">
            <div className="container">
              <div className="al_sec_ctxt do_detailss detailss">
                <h2>{donateData.donation_title}</h2>
                <div>{donateData.user.name}</div>
              </div>

              <div className="row">
                <div className="col-lg-8">
                  <div className="us_bg_imgas">
                    <div className="ovr_logss">
                      <img src="/images/gr_logo.png" alt="" />
                    </div>
                    <img src={donateData.donation_images} alt="" />
                  </div>

                  {/* ---------- Mobile View ---------- */}
                  <div className="rt_donat_lists mb_view_show">
                    <div className="plac_donates">
                      <h3>Raised {donateData.till_donation_amount} USD </h3>
                      <p>of [donateData.global_amount] USD</p>
                      <div className="skills">
                        <ProgressBar
                          completed={(
                            (donateData.till_donation_amount /
                              donateData.global_amount) *
                            100
                          ).toFixed(2)}
                          isLabelVisible={false}
                          bgColor={"#097C81"}
                          height={"12px"}
                          labelSize={"12px"}
                          transitionDuration={"0.3s"}
                        />
                      </div>
                      <div className="do_btnss">
                        {donateData?.donate_exipre_value === 1 ||
                        donateData?.donate_exipre_value2 === 1 ? (
                          <button className="org_grad" disabled>
                            Please Donate
                          </button>
                        ) : (
                          <Link
                            to={`/pay-by-bank/${page_url}`}
                            className="org_grad"
                          >
                            Please Donate
                          </Link>
                        )}

                        <Link to="" className="nrmllss">
                          Share This Campaign
                        </Link>
                      </div>
                    </div>

                    <div className="popls_listst">
                      <h3>
                        <img src="/images/gr_tik_sm.png" alt="" />{" "}
                        {donationCount}+People just donated
                      </h3>
                      <div className="mn_puplss">
                        {donationData.map((res, i) => (
                          <div className="p_lists" key={i}>
                            <ul>
                              <li>
                                <span className="usr_boxx">
                                  <img src="/images/b_sm_logo.png" alt="" />
                                </span>
                              </li>
                              <li>
                                <span>{res.doner_name}</span>
                                <h4>{res.amount} USD</h4>
                              </li>
                              <li>
                                <span className="top_doss">Top Donation</span>
                              </li>
                            </ul>
                          </div>
                        ))}

                        <div className="user_pop_filds">
                          {donateData.campaign_beneficiaries.map((res) => (
                            <div className="p_lists">
                              <ul>
                                <li>
                                  <span className="usr_boxx">
                                    {res.name.charAt(0).toUpperCase()}
                                  </span>
                                </li>
                                <li>
                                  <h4>{res.name}</h4>
                                  <span>{res.relation}</span>
                                </li>
                                <Link to="/contact">
                                  <span className="top_doss">Contact</span>
                                </Link>
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ---------- Mobile View ---------- */}

                  <div id="donates_tabs">
                    <Tabs>
                      <div className="tabs_list">
                        <TabList>
                          <Tab>Story</Tab>
                          <Tab>
                            Updates (
                            <span>
                              {donateData.id
                                ? donateData.campaign_updates.length
                                : 0}
                            </span>
                            )
                          </Tab>
                          <Tab>
                            Donations <span>({donationCount})</span>
                          </Tab>
                          <Tab>
                            Testimonials <span>({testimonialCount})</span>
                          </Tab>
                        </TabList>
                      </div>

                      <div className="content-wrapper">
                        <TabPanel>
                          <div className="tab_data_adss">
                            <div className="und_data_listst">
                              {donateData.id && (
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: donateData.donation_description,
                                    }}
                                  ></div>
                                </>
                              )}
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="tab_data_adss">
                            {donateData.id &&
                              donateData.campaign_updates.map((res, i) => (
                                <div
                                  className="und_data_listst parts_area"
                                  key={i}
                                >
                                  <div className="user_data_proff">
                                    <div className="user_mgss">
                                      <img src={res.user_image} alt="" />
                                    </div>
                                    <div className="cntetss">
                                      <p>{res.user_name}</p>
                                      <span>{TimeAgo(res.createdAt)}</span>
                                    </div>
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: res.update_description,
                                    }}
                                  ></div>
                                </div>
                              ))}
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="tab_data_adss donat_liststs_partss">
                            {donationData.map((res, i) => (
                              <div
                                className="und_data_listst parts_area donats_list"
                                key={i}
                              >
                                <div className="user_data_proff">
                                  <div className="user_mgss">
                                    <img
                                      src="/images/b_sm_logo.png"
                                      className="h-100 w-100"
                                      alt=""
                                    />
                                  </div>
                                  <div className="cntetss">
                                    <p>
                                      {res.doner_name}{" "}
                                      <span className="titlss">
                                        has donated ${res.amount}
                                      </span>
                                    </p>
                                    <span>{TimeAgo(res.created_at)}</span>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div className="view_moress">
                              <Link
                                onClick={() =>
                                  setDonationLimit(donationLimit + 5)
                                }
                              >
                                View More Donations
                              </Link>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="tab_data_adss donat_liststs_partss">
                            {testimonalData.map((res, i) => (
                              <div
                                key={i}
                                className="und_data_listst parts_area donats_list"
                              >
                                <div className="user_data_proff">
                                  <div className="user_mgss">
                                    <img src={res.user_profile} alt="" />
                                  </div>
                                  <div className="cntetss">
                                    <p>{res.user_name}</p>
                                    <span>{res.description}</span>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div className="view_moress">
                              <Link
                                onClick={() =>
                                  setTestimonialLimit(testimonialLimit + 1)
                                }
                              >
                                View More Testimonials
                              </Link>
                            </div>
                          </div>
                        </TabPanel>
                      </div>
                    </Tabs>
                  </div>
                </div>
                <div className="col-lg-4">
                  {/* ---------- Mobile View Hide ---------- */}
                  <div className="rt_donat_lists mb_view_hide">
                    <div className="plac_donates">
                      <h3>Raised {donateData.till_donation_amount} USD </h3>
                      <p>of {donateData.global_amount} USD</p>
                      <div className="skills">
                        <ProgressBar
                          completed={(
                            (donateData.till_donation_amount /
                              donateData.global_amount) *
                            100
                          ).toFixed(2)}
                          isLabelVisible={false}
                          bgColor={"#097C81"}
                          height={"12px"}
                          labelSize={"12px"}
                          transitionDuration={"0.3s"}
                        />
                      </div>
                      <div className="do_btnss">
                        {donateData?.donate_exipre_value === 1 ||
                        donateData?.donate_exipre_value2 === 1 ? (
                          <button className="org_grad" disabled>
                            Please Donate
                          </button>
                        ) : (
                          <Link
                            to={`/pay-by-bank/${page_url}`}
                            className="org_grad"
                          >
                            Please Donate
                          </Link>
                        )}

                        <Link to="" className="nrmllss">
                          Share This Campaign
                        </Link>
                      </div>
                    </div>

                    <div className="popls_listst">
                      <h3>
                        <img src="/images/gr_tik_sm.png" alt="" />{" "}
                        {donationCount}+People just donated
                      </h3>
                      <div className="mn_puplss">
                        {donationData.map((res, i) => (
                          <div className="p_lists" key={i}>
                            <ul>
                              <li>
                                <span className="usr_boxx">
                                  <img src="/images/b_sm_logo.png" alt="" />
                                </span>
                              </li>
                              <li>
                                <span>{res.doner_name}</span>
                                <h4>{res.amount} USD</h4>
                              </li>
                              <li>
                                <span className="top_doss">Top Donation</span>
                              </li>
                            </ul>
                          </div>
                        ))}

                        <div className="user_pop_filds">
                          {donateData.campaign_beneficiaries.map((res) => (
                            <div className="p_lists">
                              <ul>
                                <li>
                                  <span className="usr_boxx">
                                    {res.name.charAt(0).toUpperCase()}
                                  </span>
                                </li>
                                <li>
                                  <h4>{res.name}</h4>
                                  <span>{res.relation}</span>
                                </li>
                                <Link to="/contact">
                                  <span className="top_doss">Contact</span>
                                </Link>
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="trust_safety mb_view_hide">
                    <h2>Trust & Safety</h2>
                    <div className="und_lis_prt">
                      <span className="ic_mgss_ar">
                        <img src="/images/protection.png" alt="" />
                      </span>
                      <p>
                        Your donation is protected for 12 months by our Giving
                        Guarantee
                      </p>
                    </div>

                    <div className="und_lis_prt">
                      <span className="ic_mgss_ar">
                        <img src="/images/ambulance.png" alt="" />
                      </span>
                      <p>
                        All donations go directly to the hospital or nonprofit
                        organisation
                      </p>
                    </div>

                    <div className="und_lis_prt">
                      <span className="ic_mgss_ar">
                        <img src="/images/invention.png" alt="" />
                      </span>
                      <p>
                        Your credit card information is encrypted and not stored
                        in our system
                      </p>
                    </div>
                  </div>

                  {verificationData.map((res, i) => {
                    return (
                      <div key={i} className="rt_donat_lists mt-4 mb_view_hide">
                        <h3>
                          <img src="/images/gr_tik_sm.png" alt="" /> {res.title}
                        </h3>
                        {isExpand === i + 1 ? (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: res.description,
                              }}
                            ></div>
                          </>
                        ) : (
                          <>
                            <HtmlWithLimit
                              htmlString={res.description}
                              limit={500}
                            />
                            <Link
                              onClick={() => setIsExpand(i + 1)}
                              className="clr_tx"
                            >
                              LEARN MORE
                            </Link>
                          </>
                        )}
                      </div>
                    );
                  })}

                  <div className="btn_manages mb_view_hide mt-3">
                    <Link to="/contact" className="nrmllss">
                      Contact Support{" "}
                      <img src="/images/chevron-down.png" alt="" />{" "}
                    </Link>
                  </div>
                  {/* ---------- End Mobile View Hide ---------- */}
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="ser_v_araeea similler" id="charity_campaign">
          <div className="container">
            <div className="al_sec_ctxt">
              <h2>
                Similar Campaign{" "}
                <span className="user_lists">
                  <Link to={`/donate-category/${cateId}`}>See more</Link>
                </span>
              </h2>
            </div>
            <div className="row">
              {similerCamping.map((res, i) => {
                return (
                  <div className="col-lg-4 mb_view_hide" key={i}>
                    <Link to={`/donate-details/${res.page_url}`} key={i}>
                      <div className="cm_box_listst">
                        <div className="cm_mg_box">
                          <img src={res.donation_images} alt="banner" />
                        </div>
                        <div className="cnt_al_boxx">
                          <span className="sm_txt">
                            S.P.Foundation{" "}
                            <img src="/images/gr_tik_sm.png" alt="" />
                          </span>
                          <h3>{res.donation_title}</h3>

                          <div
                            className="max-h-130 hideScrollbar"
                            dangerouslySetInnerHTML={{
                              __html: res.donation_short_description,
                            }}
                          ></div>
                          <div className="skills">
                            <ProgressBar
                              completed={(
                                (res.till_donation_amount / res.global_amount) *
                                100
                              ).toFixed(2)}
                              isLabelVisible={false}
                              bgColor={"#097C81"}
                              height={"12px"}
                              labelSize={"12px"}
                              transitionDuration={"0.3s"}
                            />
                          </div>
                          <h6>
                            Raised {res.till_donation_amount} USD{" "}
                            <span className="sm_t">
                              of {res.global_amount} USD
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}

              {/* ---------- Mobile View ---------- */}
              <div className="container">
                <div
                  className="CharityCampaign mb_view_show abt_mgeess_bx"
                  id="about_pgsssess"
                >
                  <OwlCarousel
                    className="slider-items owl-carousel dots_allss"
                    id="three_points"
                    {...similersldd}
                  >
                    {similerCamping.map((res, i) => {
                      return (
                        <div className="item">
                          <Link to={`/donate-details/${res.page_url}`} key={i}>
                            <div className="cm_box_listst">
                              <div className="cm_mg_box">
                                <img src={res.donation_images} alt="" />
                              </div>
                              <div className="cnt_al_boxx">
                                <span className="sm_txt">
                                  S.P.Foundation{" "}
                                  <img src="/images/gr_tik_sm.png" alt="" />
                                </span>
                                <h3>{res.donation_title}</h3>
                                <div
                                  className="max-h-130 hideScrollbar"
                                  dangerouslySetInnerHTML={{
                                    __html: res.donation_short_description,
                                  }}
                                ></div>
                                <div className="skills">
                                  <ProgressBar
                                    completed={(
                                      (res.till_donation_amount /
                                        res.global_amount) *
                                      100
                                    ).toFixed(2)}
                                    isLabelVisible={false}
                                    bgColor={"#097C81"}
                                    height={"12px"}
                                    labelSize={"12px"}
                                    transitionDuration={"0.3s"}
                                  />
                                </div>
                                <h6>
                                  Raised {res.till_donation_amount} USD{" "}
                                  <span className="sm_t">
                                    of {res.global_amount} USD
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
              {/* ---------- End Mobile View ---------- */}
            </div>
          </div>
        </section>
      </div>

      <div id="Footer_arae">
        <div className="ftr_manages">
          <div className="th_parts_bxx">
            <div className="container">
              <div class="al_sec_ctxt text-center wit_tx">
                <h2>Your reliable, efficient, and impactful way to give</h2>
              </div>
              <div className="row justy_centr">
                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Efficient</h5>
                    <p>
                      Donate swiftly and <span>effortlessly</span>
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Impactful</h5>
                    <p>
                      Direct your support to the{" "}
                      <span>people and causes that matter</span> most to you
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Reliable</h5>
                    <p>
                      Your donation is safeguarded by{" "}
                      <span>the Worldtrust community</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fix_button_al mb_view_show">
        <Link to={`/pay-by-bank/${page_url}`}>Donate</Link>
      </div>
    </>
  );
};

export default Donate;
