import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from 'react-image-upload'
import { Modal, Button } from 'react-bootstrap'

const Wallet = () => {
    const [show, setShow] = useState(false);
    const [showdeposit, setShowdeposit] = useState(false);
    const [showwithdraw, setShowwithdraw] = useState(false);
    
    const handleClose = () => setShow(false);
    const videoshow = () => setShow(true);

    const handleClosedeposit = () => setShowdeposit(false);
	  const depositcl = () => setShowdeposit(true);

    const handleClosewithdraw = () => setShowwithdraw(false);
	  const withdraw = () => setShowwithdraw(true);

    

    const [startDate, setStartDate] = useState(new Date());

    function getImageFileObject(imageFile) {
        console.log({ imageFile })
      }
    
      function runAfterImageDelete(file) {
        console.log({ file })
      }

    return (
        <>


<Modal className="all_supportss sm_sizess" id="Deposit" show={showdeposit} onHide={handleClosedeposit}>
  <Modal.Header closeButton></Modal.Header>
  <Modal.Body>
	<div className="fromss_boxx">
		<div className="titalss">
		 <h3 className="text-left">Deposit</h3>
		</div>
		<form action="">
      <div className="row">
        <div className="col-lg-4">
        <div class="form-group">
			    <label>Currency</label>
          <select class="al_supportss">
            <option value="">USD</option>
            <option value="">SGD</option>
          </select>
		     </div>
        </div>
        <div className="col-lg-8">
         <div class="form-group">
			    <label>Amount</label>
			    <input type="text" class="form-control" name="" placeholder="Amount" />
		     </div>
        </div>


        <div className="col-lg-12 mt-2">
         <div class="form-group">
			    <select className="al_supportss">
            <option value="1">USD (ERC 20)</option>
          </select>
		     </div>
        </div>

        <div className="col-lg-12">
         <div class="form-group">
			    <label>Remark</label>
			    <textarea class="form-control" rows="4" cols="4" placeholder="Enter remark"></textarea>
		     </div>
        </div>

        <div className="col-lg-12 mt-3">
          <div class="form-group">
            <button type="submit" value="Deposit" class="sub_mitess">Deposit</button>
          </div>
        </div>
      </div>
		</form>
	</div>
  </Modal.Body>
</Modal>


<Modal className="all_supportss sm_sizess" id="Withdraw" show={showwithdraw} onHide={handleClosewithdraw}>
  <Modal.Header closeButton></Modal.Header>
  <Modal.Body>
	<div className="fromss_boxx">
		<div className="titalss">
		 <h3 className="text-left">Withdraw</h3>
		</div>
		<form action="">
      <div className="row">
        <div className="col-lg-4">
        <div class="form-group">
			    <label>Currency</label>
          <select class="al_supportss">
            <option value="">USD</option>
            <option value="">SGD</option>
          </select>
		     </div>
        </div>
        <div className="col-lg-8">
         <div class="form-group">
			    <label>Amount</label>
			    <input type="text" class="form-control" name="" placeholder="Amount" />
		     </div>
        </div>

        
        <div className="col-lg-12 mt-2">
         <div class="form-group">
			    <label>Security Pin 
            <span className="tool_topss"><img src="/images/i_icons.png" alt="" />
             <span className="tooltipss">Security Pin</span>
            </span>
          </label>
			    <input type="text" class="form-control" name="" placeholder="Enter Transaction password" />
		     </div>
        </div>

        <div className="col-lg-12">
         <div class="form-group">
			    <label>Remark</label>
			    <textarea class="form-control" rows="4" cols="4" placeholder="Enter remark"></textarea>
		     </div>
        </div>

        <div className="col-lg-12">
         <div class="form-group">
			    <label>Take Note:</label>
			    <p className="tx_sm_titlss">Withdrawal fees is USD5 or 3% of withdrawal amount, whichever higher. Withdrawal fees is not inclusive of gas fees for crypto transfers.</p>
		     </div>
        </div>

        <div className="col-lg-12 mt-3">
          <div class="form-group">
            <button type="submit" value="Submit Withdrawal Request" class="sub_mitess">Submit Withdrawal Request</button>
          </div>
        </div>
      </div>
		</form>
	</div>
  </Modal.Body>
</Modal>


<Modal className="all_supportss sm_sizess" id="LogoutPage" show={show} onHide={handleClose}>
  <Modal.Header closeButton></Modal.Header>
  <Modal.Body>
    <div className="fromss_boxx tr_histry">
      <div className="cntr_box_all">
        <div className="form_arar singoutss">
            <div class="sus_lists">
                <h2>Are you sure you want to Logout ?</h2>
                <p className="link_chooss">
                  <Link to="">Yes</Link>
                  <Link to="" className="act">No</Link>
                </p>
            </div>
        </div>
    </div>
   </div>
  </Modal.Body>
</Modal>

<div className="all_suport_bg">
        <section className="ser_v_araeea profiles">
            <div className="container">
                <div className="bradcams mb_view_hide"><span className="bothss"><Link to="">My Account</Link>  <img src="/images/brd_rowwo.png" alt="" />  Impact</span></div>
                <div className="row">
                    <div className="col-lg-3 mb_view_hide">
                      <div className="my_profiless">
                        <div className="top_pro_ct_mg">
                         <div className="pro_imgs"><img src="/images/my_profl.png" alt="" /></div>
                         <h4>Jackson(MU5785)</h4>
                         <p>jacksoncomp@gmail.com</p>
                        </div>

                        <ul className="profile_menus">
                           <li><Link to="/impact"><img src="/images/side_m_ic1.png" alt="" /> Impact</Link></li>
                           <li><Link to="/Profile"><img src="/images/side_m_ic2.png" alt="" /> Profile</Link></li>
                           <li><Link to="/kyc"><img src="/images/side_m_ic10.png" alt="" /> KYC</Link></li>
                           <li><Link to="/tax-information"><img src="/images/side_m_ic11.png" alt="" /> Tax Information</Link></li>
                           <li><Link to="/Gratitude"><img src="/images/side_m_ic3.png" alt="" /> Gratitude</Link></li>
                           <li><Link to="/my-events"><img src="/images/side_m_ic4.png" alt="" /> Events</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic5.png" alt="" /> Campaigns</Link></li>
                           <li><Link to="/transaction-history"><img src="/images/side_m_ic6.png" alt="" /> Transactions</Link></li>
                           <li><Link to="/wallet" className="act"><img src="/images/side_m_ic6.png" alt="" /> Wallet</Link></li>
                           <li><Link to="/referral"><img src="/images/side_m_ic7.png" alt="" /> Referral</Link></li>
                           <li><Link to="/settings"><img src="/images/side_m_ic8.png" alt="" /> Settings</Link></li> 
                           <li><Link to=""><Button variant="primary" onClick={videoshow}><img src="/images/side_m_ic9.png" alt="" /> Logout</Button></Link></li>
                        </ul>

                        <div className="merit_point">
                         <div className="pnt_logos"><img src="/images/point_logo.png" alt="" /></div>
                         <div className="cnt_textxt">
                            <h2>400</h2>
                            <p>Merit Points</p>
                         </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-lg-9">
                     <div className="form_boxx Impact">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="im_pects_box">
                            <div className="ic_arae"><img src="/images/wallet_s_im.png" alt="" /></div>
                            <div className="im_tc_texs">
                              <p>Total Donation Amount</p>
                              <h6>3,000.00 USD</h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="im_pects_box">
                            <div className="ic_arae"><img src="/images/wallet_s_im.png" alt="" /></div>
                            <div className="im_tc_texs">
                              <p>Participated Events</p>
                              <h6>2</h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="im_pects_box">
                            <div className="ic_arae"><img src="/images/calender_im.png" alt="" /></div>
                            <div className="im_tc_texs">
                              <p>Participated as Volunteer</p>
                              <h6>5</h6>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb_spacess mt-4">
                       <div className="donat_widrls">
                        <Link to="" className="act"><Button variant="primary" onClick={depositcl}>Deposit</Button></Link>
                        <Link to=""><Button variant="primary" onClick={withdraw}>Withdraw</Button></Link>
                       </div>
                      </div>


                      <div className="row mb_spacess mt-4">
                      <div className="col-lg-12">
                          <div className="Imepctgrp tabless">
                            <div className="top_cnt_impct two_partss">
                              <div className="titals_im">Transaction List</div>
                              
                              <div className="don_impacts">
                                <div className="down"><Link to="">View All</Link></div>
                              </div>
                            </div>

                            <div class="table-responsive mt-2">          
                              <table class="table table-striped al_suport_items mb-0">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Event / Campaign</th>
                                    <th>Date</th>
                                    <th>Amount</th>                                    
                                    <th>Trans. Type</th>
                                    <th>Status</th>
                                    <th className="last">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM</td>
                                    <td>200.00 USD</td>
                                    <td>Bank</td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td> 
                                  </tr>

                                  <tr>
                                    <td>2</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM</td>
                                    <td>200.00 USD</td>
                                    <td>Bank</td>
                                    <td class="goldan_tx_w">Inprocess</td>
                                    <td className="green_tx_w"><img src="/images/eye-crossed.png" alt="" /></td> 
                                  </tr>                                 

                                  <tr>
                                    <td>3</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM</td>
                                    <td>200.00 USD</td>
                                    <td>Bank</td>
                                    <td class="red_tx_w">Pending</td>
                                    <td className="green_tx_w"><img src="/images/eye-crossed.png" alt="" /></td> 
                                  </tr>                                                                  

                                  <tr>
                                    <td>4</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM</td>
                                    <td>200.00 USD</td>
                                    <td>Bank</td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td> 
                                  </tr>

                                  <tr>
                                    <td>5</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM</td>
                                    <td>200.00 USD</td>
                                    <td>Bank</td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td> 
                                  </tr>

                                  <tr>
                                    <td>6</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM</td>
                                    <td>200.00 USD</td>
                                    <td>Bank</td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td> 
                                  </tr>

                                  <tr>
                                    <td>7</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM</td>
                                    <td>200.00 USD</td>
                                    <td>Bank</td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td> 
                                  </tr>

                                  <tr>
                                    <td>8</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM</td>
                                    <td>200.00 USD</td>
                                    <td>Bank</td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td> 
                                  </tr>
                                  
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                  
                     </div>
                    </div>
                </div>
                
            </div>
        </section>
        </div>
    </>
    )
};

export default Wallet;