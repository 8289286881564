import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from 'react-image-upload'
import { Modal, Button } from 'react-bootstrap'
import CommanPagination from "./common/CommanPagination/CommanPagination";


const Careers = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const videoshow = () => setShow(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [gratitudeData, setGratitudeData] = useState();


    function getImageFileObject(imageFile) {
        console.log({ imageFile })
      }
    
      function runAfterImageDelete(file) {
        console.log({ file })
      }

    return (
      <>
        <Modal
          className="all_supportss sm_sizess"
          id="LogoutPage"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="fromss_boxx tr_histry">
              <div className="cntr_box_all">
                <div className="form_arar singoutss">
                  <div class="sus_lists">
                    <h2>Are you sure you want to Logout ?</h2>
                    <p className="link_chooss">
                      <Link to="">Yes</Link>
                      <Link to="" className="act">
                        No
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="all_suport_bg">
          <section className="ser_v_araeea profiles">
            <div className="container">
              <div className="bradcams mb_view_hide">
                <span className="bothss">
                  <Link to="">My Account</Link>{" "}
                  <img src="/images/brd_rowwo.png" alt="" /> Referral
                </span>
              </div>
              <div className="row">
                <div className="col-lg-3 mb_view_hide">
                  <div className="my_profiless">
                    <div className="top_pro_ct_mg">
                      <div className="pro_imgs">
                        <img src="/images/my_profl.png" alt="" />
                      </div>
                      <h4>Jackson(MU5785)</h4>
                      <p>jacksoncomp@gmail.com</p>
                    </div>

                    <ul className="profile_menus">
                      <li>
                        <Link to="/impact">
                          <img src="/images/side_m_ic1.png" alt="" /> Impact
                        </Link>
                      </li>
                      <li>
                        <Link to="/Profile">
                          <img src="/images/side_m_ic2.png" alt="" /> Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/kyc">
                          <img src="/images/side_m_ic10.png" alt="" /> KYC
                        </Link>
                      </li>
                      <li>
                        <Link to="/tax-information">
                          <img src="/images/side_m_ic11.png" alt="" /> Tax
                          Information
                        </Link>
                      </li>
                      <li>
                        <Link to="/Gratitude">
                          <img src="/images/side_m_ic3.png" alt="" /> Gratitude
                        </Link>
                      </li>
                      <li>
                        <Link to="/my-events">
                          <img src="/images/side_m_ic4.png" alt="" /> Events
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src="/images/side_m_ic5.png" alt="" /> Campaigns
                        </Link>
                      </li>
                      <li>
                        <Link to="/transaction-history">
                          <img src="/images/side_m_ic6.png" alt="" />{" "}
                          Transactions
                        </Link>
                      </li>
                      <li>
                        <Link to="/referral" className="act">
                          <img src="/images/side_m_ic7.png" alt="" /> Referral
                        </Link>
                      </li>
                      <li>
                        <Link to="/settings">
                          <img src="/images/side_m_ic8.png" alt="" /> Settings
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <Button variant="primary" onClick={videoshow}>
                            <img src="/images/side_m_ic9.png" alt="" /> Logout
                          </Button>
                        </Link>
                      </li>
                    </ul>

                    <div className="merit_point">
                      <div className="pnt_logos">
                        <img src="/images/point_logo.png" alt="" />
                      </div>
                      <div className="cnt_textxt">
                        <h2>400</h2>
                        <p>Merit Points</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-9">
                  <div className="form_boxx Impact Gratitude  profilss">
                    <h4 class="br_space">Referral(24)</h4>

                    <div className="row mt-2">
                      <div className="col-lg-12">
                        <div className="Imepctgrp tabless">
                          <div className="top_cnt_impct two_partss">
                            <div className="titals_im">Referral List</div>
                          </div>

                          <div class="table-responsive mt-2">
                            <table class="table table-striped al_suport_items mb-0">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Member ID</th>
                                  <th>Name</th>
                                  <th>Referral Code</th>
                                  <th>Email ID</th>
                                  <th>Introducer</th>
                                  <th className="last">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>WTI88759IJK</td>
                                  <td>Kunthea Mao</td>
                                  <td>WTI88759IJK</td>
                                  <td>worldtrust@gmail.com</td>
                                  <td>WTI88759IJ</td>
                                  <td className="green_tx_w">
                                    <img src="/images/eye_ico.png" alt="" />
                                  </td>
                                </tr>

                                <tr>
                                  <td>2</td>
                                  <td>WTI88759IJK</td>
                                  <td>Kunthea Mao</td>
                                  <td>WTI88759IJK</td>
                                  <td>worldtrust@gmail.com</td>
                                  <td>WTI88759IJ</td>
                                  <td className="green_tx_w">
                                    <img src="/images/eye-crossed.png" alt="" />
                                  </td>
                                </tr>

                                <tr>
                                  <td>3</td>
                                  <td>WTI88759IJK</td>
                                  <td>Kunthea Mao</td>
                                  <td>WTI88759IJK</td>
                                  <td>worldtrust@gmail.com</td>
                                  <td>WTI88759IJ</td>
                                  <td className="green_tx_w">
                                    <img src="/images/eye-crossed.png" alt="" />
                                  </td>
                                </tr>

                                <tr>
                                  <td>4</td>
                                  <td>WTI88759IJK</td>
                                  <td>Kunthea Mao</td>
                                  <td>WTI88759IJK</td>
                                  <td>worldtrust@gmail.com</td>
                                  <td>WTI88759IJ</td>
                                  <td className="green_tx_w">
                                    <img src="/images/eye-crossed.png" alt="" />
                                  </td>
                                </tr>

                                <tr>
                                  <td>5</td>
                                  <td>WTI88759IJK</td>
                                  <td>Kunthea Mao</td>
                                  <td>WTI88759IJK</td>
                                  <td>worldtrust@gmail.com</td>
                                  <td>WTI88759IJ</td>
                                  <td className="green_tx_w">
                                    <img src="/images/eye-crossed.png" alt="" />
                                  </td>
                                </tr>

                                <tr>
                                  <td>6</td>
                                  <td>WTI88759IJK</td>
                                  <td>Kunthea Mao</td>
                                  <td>WTI88759IJK</td>
                                  <td>worldtrust@gmail.com</td>
                                  <td>WTI88759IJ</td>
                                  <td className="green_tx_w">
                                    <img src="/images/eye-crossed.png" alt="" />
                                  </td>
                                </tr>

                                <tr>
                                  <td>7</td>
                                  <td>WTI88759IJK</td>
                                  <td>Kunthea Mao</td>
                                  <td>WTI88759IJK</td>
                                  <td>worldtrust@gmail.com</td>
                                  <td>WTI88759IJ</td>
                                  <td className="green_tx_w">
                                    <img src="/images/eye-crossed.png" alt="" />
                                  </td>
                                </tr>

                                <tr>
                                  <td>8</td>
                                  <td>WTI88759IJK</td>
                                  <td>Kunthea Mao</td>
                                  <td>WTI88759IJK</td>
                                  <td>worldtrust@gmail.com</td>
                                  <td>WTI88759IJ</td>
                                  <td className="green_tx_w">
                                    <img src="/images/eye-crossed.png" alt="" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* <div className="pages_listst">
                          <div className="row mt-4">
                            <div className="col-lg-6">
                              <div className="list_cnts">
                                Showing 1 to 9 of 5 entries
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="paginationss">
                                <ul>
                                  <li>
                                    <Link to="">Previous</Link>
                                  </li>
                                  <li>
                                    <Link to="" className="act">
                                      1
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="">2</Link>
                                  </li>
                                  <li>
                                    <Link to="">3</Link>
                                  </li>
                                  <li>
                                    <Link to="">4</Link>
                                  </li>
                                  <li>
                                    <Link to="">Next </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <CommanPagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          limit={limit}
                          setLimit={setLimit}
                          totalCounts={gratitudeData?.data?.totalElement}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
};

export default Careers;