import React, { useEffect, useState } from "react";
import CommanPagination from "../common/CommanPagination/CommanPagination";
import { Link } from "react-router-dom";
import axiosClient from "../../Services/Axios/axios";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader"; // Ensure you have installed 'react-spinners'

const UpcomingEvents = ({ videoshow }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [enrolledEventData, setEnrolledEventData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllEnrolledEvent = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(
        `/events/getUpComingEvents?language=en&pageSize=${limit}&pageNo=${currentPage}`
      );
      if (response.data && !response.data.error) {
        setEnrolledEventData(response.data);
      } else {
        setEnrolledEventData({
          data: { rows: [], totalElement: 0, totalPage: 0 },
        });
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setEnrolledEventData({
        data: { rows: [], totalElement: 0, totalPage: 0 },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEnrolledEvent();
  }, [currentPage, limit]);

  return (
    <div className="main_tabs_data">
      {loading ? (
        <div className="member-loader">
          <BeatLoader color="#097C81" />
        </div>
      ) : (
        <>
          {enrolledEventData?.data?.rows.map((item, i) => (
            <div className="un_data_list" key={item.id || i}>
              <Link to={`/events-details/${item.page_url}`}>
                <div className="usr_mgss">
                  <img
                    src={item?.image || "/images/default-event.png"}
                    alt={item?.title || "Event"}
                  />
                </div>
              </Link>
              <div className="cntent_tx">
                <div className="dts_shrerss">
                  <div className="dat_time">
                    <span className="datess">
                      <img src="/images/g_uil_calender.png" alt="Calendar" />
                      {dayjs(item.event_date).format("DD, MMM YYYY")}
                    </span>
                    <span className="spacess">&nbsp;</span>
                    <span className="datess">
                      <img src="/images/tabler_clock.png" alt="Clock" />
                      {item.event_time} - {item.event_time_end}
                    </span>
                  </div>
                  <div className="sh_linkss">
                    <div className="ul_links">
                      <ul>
                        <li>
                          <Link to="">
                            <img src="/images/g-calendar.png" alt="Calendar" />{" "}
                            &nbsp; Add to calendar
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h2>
                  {item.title}{" "}
                  <span className="peplss">
                    {item.registered_seat > 0
                      ? `${item.registered_seat || 0} people have registered`
                      : ""}
                  </span>
                </h2>
                <p>{item.description || "No description available."}</p>
                <div className="adress">
                  <div className="adrss">
                    <img
                      src="/images/g_ic_sharp-location-on.png"
                      alt="Location"
                    />
                    {item.event_location || "Location not specified"}
                  </div>
                  <div className="red_mr">
                    <Link
                      onClick={() => videoshow(item.id)}
                      className="red_mr brd_5"
                    >
                      Register
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <CommanPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
            totalCounts={enrolledEventData?.data?.totalElement || 0}
            totalPage={enrolledEventData?.data?.totalPage || 0}
          />
        </>
      )}
    </div>
  );
};

export default UpcomingEvents;
