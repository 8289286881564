import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from "react-image-upload";
import { Modal, Button } from "react-bootstrap";
import ProfileSidebar from "./common/ProfileSidebar";
import CommanPagination from "./common/CommanPagination/CommanPagination";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";
import dayjs from "dayjs";

const Impact = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [donationCountData, setDonationCountData] = useState();
  const [donationData, setDonationData] = useState();
  const videoshow = () => setShow(true);

  const getAllDonationData = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(`/users/getDashboard?language=en`);
      if (!response.data.error) {
        setDonationCountData(response.data.data);
      } else {
        setDonationCountData({});
      }

      const response1 = await axiosClient.get(
        `/users/getDashboardDonationListing?language=en&pageSize=${limit}&pageNo=${currentPage}`
      );
      if (!response1.data.error) {
        setLoading(false);
        setDonationData(response1.data);
      } else {
        setDonationData({});
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setDonationCountData([]);
    }
  };
  useEffect(() => {
    getAllDonationData();
  }, [currentPage, limit]);
  return (
    <>
      <Modal
        className="all_supportss sm_sizess"
        id="LogoutPage"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="fromss_boxx tr_histry">
            <div className="cntr_box_all">
              <div className="form_arar singoutss">
                <div class="sus_lists">
                  <h2>Are you sure you want to Logout ?</h2>
                  <p className="link_chooss">
                    <Link to="">Yes</Link>
                    <Link to="" className="act">
                      No
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="all_suport_bg">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" /> Impact
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>
              <div className="col-lg-9">
                <div className="form_boxx Impact">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="im_pects_box">
                        <div className="ic_arae">
                          <img src="/images/wallet_s_im.png" alt="" />
                        </div>
                        <div className="im_tc_texs">
                          <p>Total Donation Amount</p>
                          <h6>{donationCountData?.totalDonation} USD</h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="im_pects_box">
                        <div className="ic_arae">
                          <img src="/images/wallet_s_im.png" alt="" />
                        </div>
                        <div className="im_tc_texs">
                          <p>Participated Events</p>
                          <h6>{donationCountData?.eventParticipant}</h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="im_pects_box">
                        <div className="ic_arae">
                          <img src="/images/calender_im.png" alt="" />
                        </div>
                        <div className="im_tc_texs">
                          <p>Participated as Volunteer</p>
                          <h6>{donationCountData?.eventVolunteer}</h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mb_spacess mt-4">
                    <div className="col-lg-6">
                      <div className="Imepctgrp">
                        <div className="top_cnt_impct">
                          <div className="titals_im">Volunteering Report</div>
                          <div className="monthly_impct">
                            <select>
                              <option value="">Monthly</option>
                            </select>
                          </div>
                          <div className="don_impacts">
                            <div className="down">
                              <img src="/images/download_ic.png" alt="" />
                            </div>
                            <div className="down">
                              <img src="/images/three_do_ic.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="Imepctgrp">
                        <div className="top_cnt_impct">
                          <div className="titals_im">Donations</div>
                          <div className="monthly_impct">
                            <select>
                              <option value="">Monthly</option>
                            </select>
                          </div>
                          <div className="don_impacts">
                            <div className="down">
                              <img src="/images/download_ic.png" alt="" />
                            </div>
                            <div className="down">
                              <img src="/images/three_do_ic.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {loading ? (
                    <div className="member-loader">
                      <BeatLoader color="#097C81" />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="row mb_spacess mt-4">
                        <div className="col-lg-12">
                          <div className="Imepctgrp tabless">
                            <div className="top_cnt_impct">
                              <div className="titals_im">Donations</div>
                              <div className="monthly_impct">
                                <select>
                                  <option value="">Monthly</option>
                                </select>
                              </div>
                              <div className="don_impacts">
                                <div className="down">
                                  <Link to="">View More</Link>
                                </div>
                              </div>
                            </div>

                            <div class="table-responsive mt-2">
                              <table class="table table-striped al_suport_items mb-0">
                                <thead>
                                  <tr>
                                    <th>Sr. No</th>
                                    <th>Transaction ID</th>
                                    <th>Event / Campaign</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Merits</th>
                                    {/* <th className="last">Rating</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {donationData &&
                                    donationData?.data?.rows?.map((item, i) => {
                                      console.log(item, "item");
                                      return (
                                        <tr key={i}>
                                          <td>{i + 1}</td>
                                          <td> {item.transactionId}</td>
                                          <td>
                                            {item.campaign.donation_title}
                                          </td>
                                          <td>
                                            {dayjs(item.created_at).format(
                                              "DD, MMM YYYY HH:mm:ss A"
                                            )}
                                          </td>
                                          <td>{item.final_amount} USD</td>
                                          <td className="green_tx_w">
                                            {item.merit_points}
                                          </td>
                                          {/* <td className="goldan_tx_w flexx">
                                            5{" "}
                                            <img
                                              src="/images/star_gld.png"
                                              alt=""
                                            />
                                          </td> */}
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                            <CommanPagination
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              limit={limit}
                              setLimit={setLimit}
                              totalCounts={donationData?.data?.totalElement}
                              totalPage={donationData?.data?.totalPage}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div className="row mb_spacess mt-4">
                    <div className="col-lg-12">
                      <div className="Imepctgrp tabless">
                        <div className="top_cnt_impct two_partss">
                          <div className="titals_im">Participation</div>

                          <div className="don_impacts">
                            <div className="down">
                              <Link to="">View More</Link>
                            </div>
                          </div>
                        </div>

                        <div class="table-responsive mt-2">
                          <table class="table table-striped al_suport_items mb-0">
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Event / Campaign</th>
                                <th>Date</th>
                                <th>Check In</th>
                                <th>Check Out</th>
                                <th>Hours</th>
                                <th>Merits</th>
                                <th className="last">Rating</th>
                              </tr>
                            </thead>
                            <tbody>
                                                     {donationData &&
                                donationData?.data?.rows?.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>Chizen Lee</td>
                                      <td>{item.campaign.donation_title}</td>
                                      <td>Aug, 28 2024 02:28:22 PM </td>
                                      <td>{item.final_amount} USD</td>
                                      <td className="green_tx_w">
                                        {item.merit_points}
                                      </td>
                                      <td className="goldan_tx_w flexx">
                                        5{" "}
                                        <img
                                          src="/images/star_gld.png"
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                         <CommanPagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          limit={limit}
                          setLimit={setLimit}
                          totalCounts={donationData?.data?.totalElement}
                          totalPage={donationData?.data?.totalPage}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Impact;
