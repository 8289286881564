import { Link, useParams } from "react-router-dom";
import { React, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../Services/Axios/axios";
import PhoneInput from "react-country-phone-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
// import PhoneInput from "react-country-phone-input";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import dayjs from "dayjs";

const Donate = () => {
  const { page_url } = useParams();
  const [eventData, setEventData] = useState(null);
  const [photaData, setPhotaData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [buttonloader, setButtonloader] = useState(false);
  const [similarData, setSimilarData] = useState([]);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    country_code: "+91",
    user_type: "1",
    communication_type: "1",
    event_id: "",
    user_id: "",
  });
  const [errors, setErrors] = useState({
    fname: "",
    email: "",
    phone: "",
  });

  const [selected, setSelected] = useState({ 1: true, 2: false, 3: false });
  const handleClose = () => setShow(false);
  const videoshow = () => setShow(true);

  const handlePhoneChange = (value, country) => {
    setFormData({
      ...formData,
      fullPhone: value,
      mobile: value.slice(country.dialCode.length), // Extract mobile number without country code
      country_code: `+${country.dialCode}`,
      country: country.iso2, // Update the country code
    });
  };

  const validate = () => {
    const errors = {};

    // Name validation
    if (!formData.name.trim()) {
      errors.fname = "Name is required.";
      setButtonloader(false);
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      errors.email = "Email ID is required.";
      setButtonloader(false);
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format.";
      setButtonloader(false);
    }

    // Phone number validation
    if (!formData.mobile.trim() || !formData.country_code) {
      errors.phone = "Phone number and country code are required.";
      setButtonloader(false);
    } else if (
      !isValidPhoneNumber(
        `${formData.country_code}${formData.mobile}`,
        formData.country_code.replace("+", "")
      )
    ) {
      errors.phone = "Invalid phone number for the selected country.";
      setButtonloader(false);
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonloader(true);
    if (validate()) {
      try {
        const { data } = await axiosClient.post(`/events/booking`, {
          ...formData,
          user_type: Number(formData.user_type),
          communication_type: Number(formData.communication_type),
        });

        if (!data.error) {
          Swal.fire({
            icon: "success",
            title: "Thanks for your booking",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();

          setFormData({
            name: "",
            email: "",
            mobile: "",
            country_code: "+91",
            user_type: "1",
            communication_type: "1",
            event_id: formData.event_id, // Keep event ID intact
            user_id: userData?.id || "",
          });
        }
        setButtonloader(false);
      } catch (error) {
        setButtonloader(false);
        Swal.fire({
          icon: "error",
          title: error.response?.data?.message || "An error occurred",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? value : "") : value, // Update logic for checkboxes
    }));
  };

  const getSingleData = async () => {
    try {
      const { data } = await axiosClient.get(
        `/events/getEvents/${page_url}?language=en`
      );

      if (!data.error) {
        setEventData(data.data);
        setSimilarData(data.data.explorMore);

        setFormData((prev) => ({
          ...prev,
          event_id: data.data.id,
        }));

        setPhotaData(
          data.data.event_gallaries.filter((item) => item.gallary_type === 1)
        );
        setVideoData(
          data.data.event_gallaries.filter((item) => item.gallary_type === 2)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSingleData();
  }, [page_url]);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);

      setFormData((prev) => ({
        ...prev,
        user_id: parsedUserData.id || "",
        name: parsedUserData.first_name || "",
        email: parsedUserData.email || "",
        mobile: parsedUserData.mobile || "",
      }));
    }
  }, []);

  return (
    <>
      <Modal
        className="all_supportss sm_sizess"
        id="registeress"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="fromss_boxx">
            <div className="titalss">
              <h3>Register Now</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="unfildss" id="rediiobnt">
                <p>Register now for this event as :</p>
                <div className="rdo_boxx">
                  <p>
                    <input
                      type="radio"
                      id="test1"
                      name="user_type"
                      value="1" // Set specific value
                      onChange={(e) => handleChange(e)}
                      checked={formData.user_type === "1"} // Bind checked state
                    />
                    <label htmlFor="test1">Participant</label>
                  </p>
                  <p>
                    <input
                      type="radio"
                      id="test2"
                      name="user_type"
                      value="2" // Set specific value
                      onChange={(e) => handleChange(e)}
                      checked={formData.user_type === "2"} // Bind checked state
                    />
                    <label htmlFor="test2">Volunteer</label>
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label>
                  Name<span className="red">*</span>
                </label>
                <input
                  type="text"
                  class={`form-control ${errors.fname ? "input-error" : ""}`}
                  name="name"
                  placeholder="Enter Name"
                  value={formData.name}
                  onChange={(e) => {
                    setErrors({ ...errors, fname: "" });
                    handleChange(e);
                  }}
                />
                {errors.fname && <div className="error">{errors.fname}</div>}
              </div>

              <div class="form-group">
                <label>
                  Email ID<span className="red">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.email ? "input-error" : ""
                  }`}
                  name="email"
                  placeholder="Enter Email ID"
                  value={formData.email}
                  onChange={(e) => {
                    setErrors({ ...errors, email: "" });
                    handleChange(e);
                  }}
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>

              <div class="form-group">
                <label>
                  Phone Number<span className="red">*</span>
                </label>
                <PhoneInput
                  className={` react-tel-input ${
                    errors.phone ? "input-error" : ""
                  }`}
                  country={formData.country || "in"}
                  value={formData.fullPhone || ""}
                  onChange={(value, country) =>
                    handlePhoneChange(value, country)
                  }
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                />

                {/* <PhoneInput
                  country={"in"} // Default country
                  value={`${formData.country_code}${formData.mobile}`} // Combine country code and mobile
                  className={` react-tel-input ${
                    errors.phone ? "input-error" : ""
                  }`}
                  onChange={(value, country) =>
                    handlePhoneChange(value, country)
                  }
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                /> */}
                {errors.phone && <div className="error">{errors.phone}</div>}
              </div>

              {/* <div class="form-group check_txt" id="checkox">
                <input id="check1" name="" type="checkbox" value="yes" />
                <label for="check1">I confirm my phone number</label>
              </div> */}

              <div class="form-group">
                <div className="ck_titalss">
                  <h3>Choose your best way for communication</h3>
                </div>
                <div className="row">
                  {["1", "2", "3"].map((value, index) => (
                    <div className="col-lg-4 col-4" key={index}>
                      <div className="check_txtx" id="checkox">
                        <input
                          id={`check${value}`}
                          name="communication_type"
                          type="checkbox"
                          value={value}
                          checked={formData.communication_type === value} // Single selection logic
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              communication_type: e.target.checked ? value : "", // Update state
                            }))
                          }
                        />
                        <label htmlFor={`check${value}`}>
                          {value === "1"
                            ? "Whatsapp"
                            : value === "2"
                            ? "Phone Call"
                            : "Email"}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div class="form-group mt-4">
                <button
                  type="submit"
                  value="Register"
                  class="sub_mitess"
                  disabled={buttonloader}
                >
                  {buttonloader ? (
                    <ClipLoader color="#fff" size={"25"} />
                  ) : (
                    "Register"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      {eventData?.id && (
        <div className="all_suport_bg">
          <section className="ser_v_araeea" id="do_telss_page">
            <div className="container">
              <div className="al_sec_ctxt do_detailss">
                <h2>
                  {eventData?.title}
                  {/* Lorem ipsum dolor : sit amet Sed consequatur{" "}
                  <span className="blocks">necessitatibus</span> */}
                </h2>
              </div>

              <div className="row">
                <div className="col-lg-8">
                  <div className="us_bg_imgas">
                    <img src={eventData?.image} alt="" />
                  </div>

                  <div className="tm_context">
                    <div className="set_tm_dat">
                      <div className="st_adrss">
                        <img
                          src="/images/g_ic_sharp-location-on.png"
                          className="map_ic"
                          alt=""
                        />{" "}
                        {eventData?.event_location}
                      </div>
                      <div className="st_adrss">
                        <div className="part_right">
                          <span className="datess">
                            <img
                              src="/images/g_uil_calender.png"
                              className="datss"
                              alt=""
                            />{" "}
                            {dayjs(eventData?.event_date).format(
                              "DD, MMMM YYYY"
                            )}
                          </span>
                          <span className="datess">
                            <img
                              src="/images/tabler_clock.png"
                              className="timess"
                              alt=""
                            />{" "}
                            {eventData?.event_time} -{" "}
                            {eventData?.event_time_end}
                          </span>
                        </div>
                      </div>
                    </div>
                    <p>{eventData?.description}</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="rt_donat_lists mb_view_hide">
                    <div id="form_arar_alss">
                      <h4>Register Now</h4>
                      <form onSubmit={handleSubmit}>
                        <div className="unfildss" id="rediiobnt">
                          <p>Register now for this event as :</p>
                          <div className="rdo_boxx">
                            <p>
                              <input
                                type="radio"
                                id="test1"
                                name="user_type"
                                value="1" // Set specific value
                                onChange={(e) => handleChange(e)}
                                checked={formData.user_type === "1"} // Bind checked state
                              />
                              <label htmlFor="test1">Participant</label>
                            </p>
                            <p>
                              <input
                                type="radio"
                                id="test2"
                                name="user_type"
                                value="2" // Set specific value
                                onChange={(e) => handleChange(e)}
                                checked={formData.user_type === "2"} // Bind checked state
                              />
                              <label htmlFor="test2">Volunteer</label>
                            </p>
                          </div>
                        </div>

                        <div class="form-group">
                          <label>
                            Name<span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            class={`form-control ${
                              errors.fname ? "input-error" : ""
                            }`}
                            name="name"
                            placeholder="Enter Name"
                            value={formData.name}
                            onChange={(e) => {
                              setErrors({ ...errors, fname: "" });
                              handleChange(e);
                            }}
                          />
                          {errors.fname && (
                            <div className="error">{errors.fname}</div>
                          )}
                        </div>

                        <div class="form-group">
                          <label>
                            Email ID<span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.email ? "input-error" : ""
                            }`}
                            name="email"
                            placeholder="Enter Email ID"
                            value={formData.email}
                            onChange={(e) => {
                              setErrors({ ...errors, email: "" });
                              handleChange(e);
                            }}
                          />
                          {errors.email && (
                            <div className="error">{errors.email}</div>
                          )}
                        </div>

                        <div class="form-group" id="managss_boxx">
                          <label>
                            Phone Number<span className="red">*</span>
                          </label>
                          <div className="drp_listss"></div>
                          <PhoneInput
                            className={` react-tel-input ${
                              errors.phone ? "input-error" : ""
                            }`}
                            country={formData.country || "in"}
                            value={formData.fullPhone || ""}
                            onChange={(value, country) =>
                              handlePhoneChange(value, country)
                            }
                            inputProps={{
                              name: "phoneNumber",
                              required: true,
                              autoFocus: true,
                            }}
                          />
                          {errors.phone && (
                            <div className="error">{errors.phone}</div>
                          )}
                        </div>

                        <div class="form-group">
                          <div className="ck_titalss">
                            <h6>Choose your best way for communication</h6>
                          </div>
                          <div className="row">
                            {["1", "2", "3"].map((value, index) => (
                              <div className="col-lg-4 col-4" key={index}>
                                <div className="check_txtx" id="checkox">
                                  <input
                                    id={`check${value}`}
                                    name="communication_type"
                                    type="checkbox"
                                    value={value}
                                    checked={
                                      formData.communication_type === value
                                    } // Single selection logic
                                    onChange={(e) =>
                                      setFormData((prevData) => ({
                                        ...prevData,
                                        communication_type: e.target.checked
                                          ? value
                                          : "", // Update state
                                      }))
                                    }
                                  />
                                  <label htmlFor={`check${value}`}>
                                    {value === "1"
                                      ? "Whatsapp"
                                      : value === "2"
                                      ? "Phone Call"
                                      : "Email"}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div class="form-group mt-4">
                          <button
                            type="submit"
                            value="Donate Now"
                            class="sub_mitess"
                            disabled={buttonloader}
                          >
                            {buttonloader ? (
                              <ClipLoader color="#fff" size={"25"} />
                            ) : (
                              "Register"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="btn_manages mt-4 mb_view_show">
                    <Link to="" className="nrmllss regitterss text-center">
                      <Button variant="primary" onClick={videoshow}>
                        Register{" "}
                      </Button>
                    </Link>
                  </div>

                  <div className="btn_manages mb_view_hide mt-3">
                    <Link to="" className="nrmllss">
                      Share Event{" "}
                      <img src="/images/w-share-android.png" alt="" />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="ser_v_araeea pt-0 ev_tabdss evnetsts">
            <div className="container">
              <div className="entnts_tabss">
                <Tabs>
                  <div className="tabs_list">
                    <TabList>
                      <Tab>Photos</Tab>
                      <Tab>Videos</Tab>
                    </TabList>
                  </div>

                  <div className="content-wrapper">
                    <TabPanel>
                      <div className="main_tabs_data">
                        <div className="row">
                          {photaData?.map((item) => {
                            return (
                              <div className="col-lg-3 col-6">
                                <div className="ph_gellry">
                                  <img src={item?.image} alt="" />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="main_tabs_data">
                        <div className="row">
                          {videoData?.map((item, i) => {
                            return (
                              <div className="col-lg-6 col-6" key={i}>
                                <div className="vedioo">
                                  <iframe
                                    width="100%"
                                    height="400"
                                    src={item.image}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
          </section>

          <section className="ser_v_araeea evnetsts pt-0">
            <div className="container">
              <div className="al_sec_ctxt">
                <h2>Similar Events</h2>
              </div>
              <div className="entnts_tabss">
                <div className="content-wrapper mt-0">
                  <div className="main_tabs_data">
                    {similarData?.map((item, i) => {
                      return (
                        <div className="un_data_list">
                          <Link to={`/events-details/${item.page_url}`}>
                            <div className="usr_mgss">
                              <img src={item.image} alt="" />
                            </div>
                          </Link>
                          <div className="cntent_tx">
                            <div className="dat_time">
                              <span className="datess">
                                <img src="/images/g_uil_calender.png" alt="" />{" "}
                                {dayjs(item.event_date).format("DD, MMMM YYYY")}
                              </span>
                              <span className="spacess">&nbsp;</span>
                              <span className="datess">
                                <img src="/images/tabler_clock.png" alt="" />{" "}
                                {item.event_time} - {item.event_time_end}
                              </span>
                            </div>
                            <Link to={`/events-details/${item.page_url}`}>
                              <h2>{item.title}</h2>
                            </Link>
                            <p>{item.description}</p>
                            <div className="adress">
                              <div className="adrss">
                                <img
                                  src="/images/g_ic_sharp-location-on.png"
                                  alt=""
                                />{" "}
                                {item.event_location}
                              </div>
                              <div className="red_mr">
                                <Link to="" className="red_mr">
                                  <Button variant="primary" onClick={videoshow}>
                                    Register Now
                                    <img
                                      src="/images/ar_right_icon.png"
                                      alt=""
                                    />
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <div id="Footer_arae">
        <div className="ftr_manages">
          <div className="th_parts_bxx">
            <div className="container">
              <div class="al_sec_ctxt text-center wit_tx">
                <h2>Your reliable, efficient, and impactful way to give</h2>
              </div>
              <div className="row justy_centr">
                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Efficient</h5>
                    <p>
                      Donate swiftly and <span>effortlessly</span>
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Impactful</h5>
                    <p>
                      Direct your support to the{" "}
                      <span>people and causes that matter</span> most to you
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Reliable</h5>
                    <p>
                      Your donation is safeguarded by{" "}
                      <span>the Worldtrust community</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donate;
